import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CPwebsite from "../../assets/homePageAssets/CPwebsite.png";
import OEwebsite from "../../assets/homePageAssets/onEditorWebsite.png";
import urxImg from "../../assets/homePageAssets/urxWebsite.png";
import DMImg from "../../assets/homePageAssets/DMwebsite.png";
import { FaExternalLinkAlt } from "react-icons/fa";

const RecentClients = () => {
  return (
    <Container>
      <div className="title">
        <h2>
          OUR <br />
          <span>Recent Clients</span>
        </h2>
        <p>
          Here are 4 of our latest clients we signed. <br />{" "}
          <Link to={"/services/web-services"}>See more</Link>
        </p>
      </div>
      <div className="items">
        <div className="item">
          <Link
            to={"https://www.instagram.com/p/C3DuqodsmsL/?img_index=1"}
            target="_blank"
          >
            <img src={CPwebsite} alt="" />
          </Link>
          <h2>Coolest Parfume</h2>
          <Link to={"https://coolestparfume.com/"} target="_blank">
            <p>
              Visit website <FaExternalLinkAlt className="icon" />
            </p>
          </Link>
        </div>
        <div className="item">
          <Link to={"https://www.instagram.com/p/C07JVELNCn6/"} target="_blank">
            <img src={urxImg} alt="" />
          </Link>
          <h2>Urban RenderX</h2>
          <Link to={"https://urban-renderx.web.app/"} target="_blank">
            <p>
              Visit website <FaExternalLinkAlt className="icon" />
            </p>
          </Link>
        </div>
        <div className="item">
          <Link to={"https://www.instagram.com/p/C2PVHw-tQQT/"} target="_blank">
            <img src={DMImg} alt="" />
          </Link>
          <h2>Drapespire Marketing</h2>
          <Link to={"https://drapespire-marketing.web.app/"} target="_blank">
            <p>
              Visit website <FaExternalLinkAlt className="icon" />
            </p>
          </Link>
        </div>
        <div className="item">
          <Link to={"https://www.instagram.com/p/C1F0c8vMuix/"} target="_blank">
            <img src={OEwebsite} alt="" />
          </Link>
          <h2>On Editor</h2>
          <Link to={"https://on-editor.web.app/"} target="_blank">
            <p>
              Visit website <FaExternalLinkAlt className="icon" />
            </p>
          </Link>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  .title {
    text-align: center;
    h2 {
      font-size: 2.7em;
      line-height: 1.1em;
      font-weight: 400;
      span {
        color: var(--primaryColor);
        font-weight: 500;
        font-family: "Comfortaa", cursive;
      }
    }
    p {
      color: var(--fontSecondaryColor);
      font-size: 15px;
      font-weight: 300;
    }
  }
  .items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
    margin-top: 2em;
    .item {
      width: 23%;
      max-width: 1000px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10%;
      img {
        width: 100%;
        border-radius: 10%;
      }
      h2 {
        font-weight: 400;
        font-size: 22px;
      }
      p {
        display: flex;
        align-items: center;
        gap: 7px;
        color: var(--fontSecondaryColor);
        font-weight: 300;
        margin-bottom: 20px;
      }
    }
  }
  @media (max-width: 920px) {
    .items {
      .item {
        width: 48%;
      }
    }
  }
  @media (max-width: 500px) {
    .items {
      .item {
        width: 98%;
      }
    }
  }
`;

export default RecentClients;
