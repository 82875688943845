import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const WebServicesComp = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <motion.div
        className="item"
        ref={ref}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.3 }}
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/web-designing-4027284-3328602.png?f=webp"
          alt=""
        />
        <h2>Web Design</h2>
        <p>
          We create one-of-a-kind, eye-catching website designs that perfectly
          match your brand and goals.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.6 }}
      >
        <img
          src="https://static.vecteezy.com/system/resources/previews/009/298/359/original/3d-illustration-of-web-development-png.png"
          alt=""
        />
        <h2>Web Development</h2>
        <p>
          We build and maintain your website's technical aspects, ensuring
          smooth functionality while you concentrate on your business.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.9 }}
      >
        <img
          src="https://static.vecteezy.com/system/resources/previews/010/986/963/original/3d-illustration-of-analysis-seo-business-png.png"
          alt=""
        />
        <h2>SEO</h2>
        <p>
          Get discovered online. Be the first result when your clients search
          for anything related to your business, brand, or anything.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 1.2 }}
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/ui-ux-design-5996826-4972885.png"
          alt=""
        />
        <h2>Redesign</h2>
        <p>
          Transform your outdated website into a modern, user-friendly platform
          that captures and retains visitors' attention.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 1.5 }}
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/ecommerce-website-4678097-3892047.png?f=webp"
          alt=""
        />
        <h2>eCommerce</h2>
        <p>
          Seamlessly sell products online, from managing your inventory to
          secure payment processing, enhancing your online sales and customer
          experience.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 1.8 }}
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/software-development-8533753-6716438.png"
          alt=""
        />
        <h2>Maintenance and Management</h2>
        <p>
          Enjoy worry-free website management, ensuring your online presence is
          always secure, up-to-date, and running smoothly.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 2.1 }}
      >
        <img
          src="https://cdn1.iconfinder.com/data/icons/business-training-9/512/document.png"
          alt=""
        />
        <h2>Web Copywriting</h2>
        <p>
          Craft persuasive, customer-focused messages that drive action and
          conversions on your website.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 2.4 }}
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/website-dashboard-9173182-7501956.png"
          alt=""
        />
        <h2>Web Content</h2>
        <p>
          Deliver informative and engaging articles and blog posts that inform
          and entertain, positioning your brand as an industry authority.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 2.7 }}
      >
        <img
          src="https://static.vecteezy.com/system/resources/previews/008/507/998/original/illustration-of-graph-and-chart-for-data-analytics-3d-render-png.png"
          alt=""
        />
        <h2>Web Analysis</h2>
        <p>
          Make data-driven decisions with insightful website analytics, helping
          you understand user behavior and improve your online strategies.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 3 }}
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/digital-marketing-5507151-4596633.png"
          alt=""
        />
        <h2>Web Marketing</h2>
        <p>
          Reach your target audience and boost brand awareness through tailored
          digital marketing strategies that drive results.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 3.3 }}
      >
        <img
          src="https://static.vecteezy.com/system/resources/previews/010/842/423/original/3d-illustration-online-ads-png.png"
          alt=""
        />
        <h2>Web Ads</h2>
        <p>
          Create attention-grabbing and targeted advertisements that enhance
          your online presence and drive qualified leads to your business.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 3.6 }}
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/web-hosting-5425439-4533186.png"
          alt=""
        />
        <h2>Web Hosting</h2>
        <p>
          Secure and maintain your website with reliable hosting solutions,
          ensuring uninterrupted online performance and data protection.
        </p>
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  padding: 5em 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em 3%;
  flex-wrap: wrap;
  .item {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
    img {
      width: 140px;
    }
    h2 {
      font-weight: 400;
      font-family: "Comfortaa", cursive;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
  @media (max-width: 1130px) {
    .item {
      width: 48%;
    }
  }
  @media (max-width: 500px) {
    .item {
      width: 98%;
      img {
        width: 180px;
      }
    }
  }
`;

export default WebServicesComp;
