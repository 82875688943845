import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion, useAnimation, useInView } from "framer-motion";

const OurServices = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="items">
        <div className="top">
          <motion.div
            variants={{
              hidden: { opacity: 0, x: -300 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{ duration: 1, delay: 0.4, type: "spring" }}
            className="item i1"
          >
            <h2>
              Our Main <br /> <span>Services</span>
            </h2>
            <p>
              These are 3 of our main services we offer, with years of
              experience on each of them!
            </p>
            <Link to={"/services"} className="link-styles">
              <button>DISCOVER ALL</button>
            </Link>
          </motion.div>
          <motion.div className="item i2" ref={ref}>
            <img
              src="https://cdn-icons-png.flaticon.com/256/2904/2904786.png"
              alt="ASites Agency Content Creation Services Image"
            />
            <h2>Content Creation & Copywriting</h2>
            <p>
              Engage and attract customers with quality content like blog posts
              and videos, driving online growth and building your brand's
              authority.
            </p>
          </motion.div>
        </div>
        <div className="bottom">
          <motion.div className="item i2">
            <img
              src="https://cdn-icons-png.flaticon.com/512/2210/2210153.png"
              alt="ASites Agency - Web Design & Development Services Image"
            />
            <h2>Web Services</h2>
            <p>
              We build websites that attract customers, tell your story, and
              drive sales, helping you expand your online reach and business.
            </p>
          </motion.div>

          <motion.div className="item i2 last">
            <img
              src="https://cdn-icons-png.flaticon.com/512/5681/5681665.png"
              alt="ASites Agency - SEO & Marketing Services Image"
            />
            <h2>SEO & Marketing</h2>
            <p>
              We boost your website's visibility on Google, making it easier for
              potential customers to find you and increasing your online growth.
            </p>
            <Link to={"/services"}></Link>
          </motion.div>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 2% 9em 2%;
  background: var(--backgroundGradient2M);
  background-size: 67% 90%, 0% 80%, 110% 110%;
  background-repeat: no-repeat;
  .items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    .item {
      background: var(--primaryBackgroundColor);
      img {
        width: 100px;
      }
      h2 {
        color: var(--fontSecondaryColor);
        font-weight: 800;
        font-family: "Comfortaa", cursive;
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
        line-height: 22px;
      }
      button {
        font-size: 17px;
        background: var(--fontPrimaryColor);
        color: var(--primaryBackgroundColor);
        padding: 14px 17px;
        border: none;
        margin-top: 10px;
        cursor: pointer;
        transition: 0.4s;
        :hover {
          box-shadow: 0 0 10px 3px var(--shadowColor);
        }
      }
    }
    .top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3em;
      width: 37%;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      gap: 3em;
      margin-top: 3em;
    }
    .i1 {
      width: 85%;
      background: none;
      max-width: 400px;
      h2 {
        font-size: 2.7em;
        line-height: 1.1em;
        font-weight: 400;
        span {
          color: var(--primaryColor);
          font-weight: 500;
          font-family: "Comfortaa", cursive;
        }
      }
      p {
        color: var(--fontSecondaryColor);
        font-size: 15px;
      }
    }
    .i2 {
      align-self: flex-end;
      margin-right: 4em;
      width: 340px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      box-shadow: 12px 12px 19px var(--shadowColor1),
        -12px -12px 19px var(--shadowColor2);
    }
  }
  @media (min-width: 2040px) {
    .items {
      .item {
        padding: 1.3em;
        img {
          width: 150px;
        }
        h2 {
          font-size: 27px;
        }
        p {
          font-size: 22px;
        }
      }
      .i2 {
        width: 500px;
        height: 400px;
      }
      .i1 {
        h2 {
          font-size: 3.2em;
        }
      }
    }
  }
  @media (max-width: 1020px) {
    .items {
      .i2 {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 840px) {
    .items {
      flex-direction: column;
      .top {
        flex-direction: row;
        width: 90%;
      }
      .bottom {
        flex-direction: row;
        width: 90%;
        margin-left: 2em;
      }
      .i1 {
        width: 50%;
      }
    }
  }
  @media (max-width: 600px) {
    .items {
      .top {
        flex-direction: column;
        width: 90%;
      }
      .i1 {
        width: 90%;
      }
      .i2 {
        width: 100%;
        margin-right: 1%;
      }
      .bottom {
        flex-direction: column;
        width: 91%;
        margin-left: 1.4em;
      }
    }
  }
  @media (max-width: 420px) {
    .items {
      .i2 {
        width: 98%;
        margin-left: 3em;
      }
    }
  }
`;

export default OurServices;
