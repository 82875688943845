import React, { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import data from "../components/data/packages";

const SelectedPackage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id } = useParams();
  return (
    <Container>
      {data
        .filter((item) => item.id === id)
        .map((card) => (
          <>
            <div className="banner">
              <div className="left">
                <h1>{card.bannerQuote}</h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi dolore facere dicta. Pariatur officiis dolorum
                  magni. Libero temporibus impedit dolore?
                </p>
                <span>
                  {card.tags &&
                    Array.isArray(card.tags) &&
                    card.tags.map((tag, index) => <p key={index}>{tag}</p>)}
                </span>
              </div>
              <img src={card.bannerImg} alt="" />
            </div>
            <div className="servicesAndFeatures">
              <div className="services">
                <h2>Services</h2>
                <div>
                  {card.services &&
                    Array.isArray(card.services) &&
                    card.services.map((service, index) => (
                      <>
                        <h3 key={index}>{service}</h3>
                        <hr />
                      </>
                    ))}
                </div>
              </div>
              <hr />
              <div className="features">
                <h2>Features</h2>
                <div>
                  {card.features &&
                    Array.isArray(card.features) &&
                    card.features.map((feature, index) => (
                      <>
                        <h3 key={index}>{feature}</h3>
                        <hr />
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="description">
              <p>{card.description}</p>
            </div>
            <div className="benefits">
              <div className="title">
                <h2>What Your Benefit From This Package</h2>
                <p>This is what you will benefit by getting the {card.name}</p>
              </div>
              <div className="items">
                {card.benefits &&
                  Array.isArray(card.benefits) &&
                  card.benefits.map((benefit, index) => (
                    <>
                      <h2 key={index}>{benefit}</h2>
                      <hr />
                    </>
                  ))}
              </div>
            </div>
          </>
        ))}
    </Container>
  );
};
const Container = styled.div`
  padding: 2em 0%;
  background-color: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  .banner {
    padding: 4em 4%;
    background: var(--backgroundGradient2T);
    background-size: 65% 90%, 100% 100%, 110% 110%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    height: 80vh;
    img {
      width: 47%;
      object-fit: scale-down;
      max-width: 650px;
    }
    .left {
      display: flex;
      margin-top: 2%;
      flex-direction: column;
      gap: 1em;
      h1 {
        color: var(--fontSecondaryColor);
        line-height: 1.1em;
        font-size: 3.3em;
        font-weight: 400;
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
      span {
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
        p {
          padding: 4px 10px;
          background-color: var(--secondaryBackgroundColor);
          border-radius: 1em;
        }
      }
    }
  }
  .servicesAndFeatures {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin: 4em 4%;
    .services {
      text-align: end;
    }
    div {
      h2 {
        color: var(--primaryColor);
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 2em;
      }
      div {
        h3 {
          font-size: 2em;
          font-weight: 300;
        }
        hr {
          border-bottom: var(--fontSecondaryColor);
        }
      }
    }
  }
  .description {
    padding: 7em 10%;
    p {
      color: var(--fontSecondaryColor);
      font-weight: 400;
    }
  }
  .benefits {
    padding: 4em 7%;
    .title {
      text-align: center;
      margin-bottom: 2.2em;
      h2 {
        color: var(--primaryColor);
        font-weight: 400;
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
    }
    .items {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;
      flex-wrap: wrap;
      h2 {
        color: var(--primaryColorDark);
        font-weight: 400;
        text-align: center;
      }
    }
  }
  @media (min-width: 1700px) {
    .banner {
      background-size: 35% 90%, 100% 100%, 110% 110%;
      height: auto;
      .left {
        h1 {
          font-size: 5em;
        }
      }
    }
    .servicesAndFeatures {
      padding: 8em 4%;
      div {
        div {
          h3 {
            font-size: 2.4em;
          }
        }
      }
    }
    .description {
      p {
        font-size: 2em;
      }
    }
    .benefits {
      .title {
        h2 {
          font-size: 2.7em;
        }
        p {
          font-size: 1.3em;
        }
      }
      .items {
        h2 {
          font-size: 3em;
        }
      }
    }
  }
  @media (min-width: 1400px) {
    .banner {
      height: auto;
    }
    .servicesAndFeatures {
      background-size: 45% 90%, 90% 130%, 110% 110%;
    }
    .description {
      p {
        font-size: 1.7em;
      }
    }
  }
  @media (max-width: 1000px) {
    .banner {
      height: auto;
      .left {
        h1 {
          font-size: 2.7em;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .banner {
      flex-direction: column;
      align-items: center;
      height: auto;
      .left {
        text-align: center;
        margin-top: 0%;
        align-items: center;
        span {
          gap: 10px;
          justify-content: center;
        }
        h1 {
          font-size: 2em;
        }
      }
      img {
        width: 70%;
      }
    }
  }
  @media (max-width: 610px) {
    .servicesAndFeatures {
      div {
        div {
          h3 {
            font-size: 1.3em;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .banner {
      .left {
      }
      img {
        width: 90%;
      }
    }
  }
  @media (max-width: 450px) {
    .banner {
      .left {
        h1 {
          font-size: 1.7em;
        }
      }
    }
    .servicesAndFeatures {
      flex-direction: column;
      hr {
        display: none;
      }
    }
  }
`;

export default SelectedPackage;
