import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { FaCheckCircle } from "react-icons/fa";

const GetAProposal = () => {
  const openCalendlyPopup = () => {
    window.open(
      "https://calendly.com/asitesagency/free-consultation",
      "calendlyPopup",
      "width=800,height=600"
    );
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "ASites Agency - Get A Proposal";
  }, []);

  const [alert, setAlert] = useState(false);
  const handleAlert = () => {
    setAlert(true);

    setTimeout(() => {
      setAlert(false);
    }, 2000);
  };
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      emailjs
        .sendForm(
          "asites-agency-contact",
          "template_9rdjmr8",
          e.target,
          "-rXsp2xIJ98qOBflT"
        )
        .then((res) => {
          console.log(res);
          navigate("/thank-you-page");
          handleAlert();
        })
        .catch((err) => console.log(err));
    } else {
      console.log("Please fill in all fields.");
    }
  };

  return (
    <Container>
      {alert ? (
        <div className="alert">
          <FaCheckCircle className="icon" />
          <p>Proposal Requested!</p>
        </div>
      ) : (
        ""
      )}
      <div className="left">
        <h1>Secure a Custom Proposal to Propel Your Business.</h1>
        <p>
          Explore bespoke solutions tailored to your business needs. <br />{" "}
          Request a proposal and unlock your path to exponential growth.
        </p>
        <button onClick={openCalendlyPopup}>BOOK A FREE CALL</button>
      </div>
      <form onSubmit={sendEmail}>
        <input type="text" name="name" placeholder="Full Name" required />
        <input
          type="text"
          name="companyName"
          placeholder="Company Name"
          required
        />
        <input type="email" name="email" placeholder="Email" />
        <input
          type="phone"
          name="phoneNumber"
          placeholder="Phone Number (Optional)"
          required
        />
        <input
          type="link"
          name="website"
          placeholder="Website (if applicable)"
        />
        <textarea
          name="projectDescription"
          cols="20"
          rows="4"
          placeholder="Project description"
          required
        ></textarea>
        <button type="submit">Submit Proposal</button>
      </form>
    </Container>
  );
};

const Container = styled.div`
  color: var(--fontPrimaryColor);
  background: var(--primaryBackgroundColor);
  padding: 7em 4% 3em 4%;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: "Montserrat", cursive;
  background-image: var(--backgroundGradient2T);
  background-size: 100% 100%, 130% 100%, 100% 90%;
  background-repeat: no-repeat;
  .alert {
    position: fixed;
    top: 5em;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 0 12px 1px var(--shadowColor);
    padding: 14px 17px;
    border-radius: 20px 0 0 20px;
    transition: 0.4s;
    .icon {
      color: #119e11;
    }
  }
  .left {
    margin-top: 5%;
    h1 {
      font-size: 3.4em;
      line-height: 1.1em;
      font-weight: 400;
      span {
        color: var(--primaryColor);
      }
    }
    p {
      margin-right: 17%;
      color: var(--fontSecondaryColor);
      font-weight: 300;
      margin-top: 1em;
    }
    button {
      margin-top: 1em;
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 17px;
      font-size: 18px;
      font-family: "Comfortaa", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 1000px;
    padding: 2em;
    h2 {
      font-weight: 300;
      text-align: center;
      margin-bottom: 10px;
    }
    input,
    textarea {
      font-family: "Montserrat", cursive;
      padding: 10px;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid var(--primaryColorDark);
      background: none;
      color: var(--fontPrimaryColor);
      outline: none;
      ::placeholder {
        color: var(--fontSecondaryColor);
      }
    }
    button {
      margin-top: 1em;
      display: flex;
      align-self: flex-start;
      margin: auto;
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 17px;
      font-size: 18px;
      font-family: "Comfortaa", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
  }
  @media (max-width: 1300px) {
    padding: 4em 7%;
    .left {
      h1 {
        font-size: 3em;
      }
    }
  }
  @media (max-width: 770px) {
    flex-direction: column;
    align-items: center;
    .left {
      width: 100%;
      h1 {
        font-size: 3em;
      }
    }
    form {
      width: 90%;
    }
  }
  @media (max-width: 550px) {
    padding: 4em 6%;
    form {
      width: 100%;
      padding: 10px;
    }
  }
  @media (max-width: 550px) {
    .left {
      h1 {
        font-size: 2.4em;
      }
    }
  }
  @media (max-width: 410px) {
    .left {
      h1 {
        font-size: 2em;
      }
    }
  }
`;

export default GetAProposal;
