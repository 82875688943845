import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import bannerImg from "../assets/servicesPageAssets/webServicesBannerImg.png";
import dots from "../assets/homePageAssets/dots.png";
import { motion, useAnimation, useInView } from "framer-motion";
import WebServicesComp from "../components/servicesComponents/WebServicesComp";
import WebServicesStats from "../components/servicesComponents/WebServicesStats";
import WebServicesFAQ from "../components/servicesComponents/WebServicesFAQ";
import WebsiteTypes from "../components/servicesComponents/WebsiteTypes";

const WebServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Web Services - ASites Agency";
  }, []);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="banner">
        <motion.img
          src={bannerImg}
          alt="ASites Agency Services Page Image"
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -100 }}
          transition={{
            duration: 0.7,
            bounce: 0.3,
            delay: 0.2,
            type: "spring",
          }}
        />
        <div className="details">
          <motion.h1
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: 70 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 0.3,
              type: "spring",
            }}
          >
            Creating the Path <br /> to{" "}
            <motion.span
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 70 }}
              transition={{
                duration: 0.7,
                bounce: 0.3,
                delay: 1,
                type: "spring",
              }}
            >
              Digital Success
            </motion.span>
          </motion.h1>
          <motion.h3
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 1.2,
            }}
          >
            Web Services that Deliver Beyond Pixels.
          </motion.h3>
          <div className="keywords">
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                bounce: 0.3,
                delay: 1.4,
                type: "spring",
              }}
            >
              Design
            </motion.p>
            <motion.hr
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 2,
              }}
            />
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                bounce: 0.3,
                delay: 1.6,
                type: "spring",
              }}
            >
              Development
            </motion.p>
            <motion.hr
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 2,
              }}
            />
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.8,
              }}
            >
              Redesign
            </motion.p>
            <motion.hr
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 2,
              }}
            />
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                bounce: 0.3,
                delay: 2,
                type: "spring",
              }}
            >
              eCommerce
            </motion.p>
          </div>
          <img src={dots} className="dots" alt="" />
        </div>
      </div>
      <div className="text">
        <h1>YOUR VISION,</h1>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 200 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.7, delay: 0.4, type: "spring" }}
        >
          OUR CREATION!
        </motion.h1>
      </div>
      <WebServicesStats />
      <WebServicesComp />
      <WebsiteTypes />
      <WebServicesFAQ />
    </Container>
  );
};
const Container = styled.div`
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  .banner {
    background: var(--backgroundGradient2M);
    background-size: 30% 67%, 100% 100%, 97% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3%;
    padding: 5em 4% 1em 4%;
    position: relative;
    img {
      width: 40%;
      max-width: 800px;
    }
    .dots {
      position: absolute;
      width: 140px;
      opacity: 0.08;
      top: 10%;
      right: 10%;
    }
    .details {
      h1 {
        font-size: 3.7em;
        line-height: 1.1em;
        font-weight: 300;
        span {
          color: var(--primaryColor);
          font-weight: 400;
        }
      }
      h3 {
        color: var(--fontSecondaryColor);
        font-weight: 300;
        margin: 7px 0 1em 0;
      }
      .keywords {
        display: flex;
        gap: 1em;
        margin-top: 1em;
      }
    }
  }
  .text {
    background: var(--backgroundGradient3);
    padding: 8em 7%;
    h1 {
      font-size: 8em;
      line-height: 1em;
      font-weight: 300;
      :nth-child(2) {
        background: var(--gradientBackground);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  @media (max-width: 1190px) {
    .text {
      padding: 7em 3%;
      h1 {
        font-size: 7em;
      }
    }
  }
  @media (max-width: 1080px) {
    .banner {
      .details {
        h1 {
          font-size: 3.2em;
        }
      }
    }
  }
  @media (max-width: 950px) {
    .text {
      h1 {
        font-size: 5.5em;
      }
    }
  }
  @media (max-width: 880px) {
    .banner {
      .details {
        h1 {
          font-size: 2.8em;
        }
        .keywords {
          gap: 10px;
        }
      }
    }
  }
  @media (max-width: 715px) {
    .banner {
      flex-direction: column-reverse;
      gap: 2em;
      .details {
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        h1 {
          font-size: 3.4em;
        }
        .keywords {
          margin-top: 5px;
        }
      }
      img {
        width: 70%;
      }
    }
    .text {
      h1 {
        font-size: 4.8em;
      }
    }
  }
  @media (max-width: 550px) {
    .banner {
      .details {
        h1 {
          font-size: 2.7em;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .text {
      h1 {
        font-size: 3.5em;
      }
    }
  }
  @media (max-width: 430px) {
    .banner {
      .details {
        h1 {
          font-size: 2.3em;
        }
        .keywords {
          gap: 7px;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
  }
`;

export default WebServices;
