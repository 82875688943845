import React, { useEffect } from "react";
import styled from "styled-components";
import PackagesBanner from "../components/packagesComponents/PackagesBanner";
import PackagesComp from "../components/packagesComponents/PackagesComp";
import WebsitePackages from "../components/packagesComponents/WebsitePackages";
import DigitalMarketingPackages from "../components/packagesComponents/DigitalMarketingPackages";
import ContentCreationPackages from "../components/packagesComponents/ContentCreationPackages";
import BrandingPackages from "../components/packagesComponents/BrandingPackages";

const Packages = ({ theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Packages - ASites Agency";
  }, []);
  return (
    <Container>
      <PackagesBanner theme={theme} />
      <WebsitePackages />
      <DigitalMarketingPackages />
      <ContentCreationPackages />
      <BrandingPackages />
      {/* <PackagesComp /> */}
    </Container>
  );
};
const Container = styled.div`
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
`;

export default Packages;
