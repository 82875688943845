import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { RiDoubleQuotesR } from "react-icons/ri";
import { motion, useAnimation, useInView } from "framer-motion";

const Testimonials = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="topSection">
        <h1>What Clients and Collaborators Are Saying</h1>
        <p>
          Our top priority is client satisfaction. It really means a lot to us
          and we take good care of that.
        </p>
      </div>
      <div className="bottomSection">
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 200 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.7, delay: 0.4, type: "spring" }}
          className="item"
        >
          <RiDoubleQuotesR className="icon" />
          <p>
            I needed a informational website for my mobile shop. I hired ASites,
            and they really took care of everything for a quite reasonable
            price. I picked the premium package, and it was totally worth it.
          </p>
          <div>
            <span>
              <h2>Alano Jiminez</h2>
              <p>First Client</p>
            </span>
          </div>
        </motion.div>
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 200 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.7, delay: 1, type: "spring" }}
          className="item"
        >
          <RiDoubleQuotesR className="icon" />
          <p>
            I was running ads on my social media accounts by myself, but the
            design of the ads wasn't that good, so I needed someone to improve
            that, ASites... They edited really modern and cool ad designes and
            also made a free logo for me. Thank You ASites.
          </p>
          <div>
            <span>
              <h2>Kastriot Gashi</h2>
              <p>Client</p>
            </span>
          </div>
        </motion.div>
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 200 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.7, delay: 0.7, type: "spring" }}
          className="item"
        >
          <RiDoubleQuotesR className="icon" />
          <p>
            I run a real estate business in Croatia. I have a website for my
            business but it had low SEO performance. At first, I didn't even
            know that could be optimized until I found out about ASites, they
            took care of it, and now my website shows on the first page in
            Croatia.
          </p>
          <div>
            <span>
              <h2>Denis Kopić</h2>
              <p>Client</p>
            </span>
          </div>
        </motion.div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  background: var(--backgroundGradient2T);
  background-size: 40% 100%, 90% 130%, 49% 100%;
  background-repeat: no-repeat;
  .topSection {
    display: flex;
    justify-content: center;
    gap: 1em;
    h1 {
      line-height: 1.1em;
      font-size: 3em;
      font-weight: 400;
      max-width: 14em;
      color: var(--primaryColor);
    }
    p {
      margin-top: 20px;
      max-width: 30em;
      color: var(--fontSecondaryColor);
    }
  }
  .bottomSection {
    flex-wrap: wrap;
    margin-top: 3em;
    display: flex;
    justify-content: center;
    gap: 2em;
    .item {
      width: 30%;
      max-width: 600px;
      display: flex;
      flex-direction: column;
      gap: 1.4em;
      .icon {
        font-size: 3em;
        color: var(--fontPrimaryColor);
      }
      p {
        color: var(--fontSecondaryColor);
      }
      div {
        display: flex;
        align-items: center;
        gap: 1em;
        h2 {
          color: var(--primaryColor);
          font-size: 17px;
          font-weight: 400;
        }
        p {
          color: var(--fontSecondaryColor);
          font-size: 14px;
        }
      }
    }
  }
  @media (min-width: 2100px) {
    background-size: 30% 100%, 70% 130%, 100% 100%;
  }
  @media (max-width: 960px) {
    background-size: 100% 120%, 100% 130%, 89% 100%;
    .topSection {
      h1 {
        width: 50%;
      }
      p {
        width: 50%;
      }
    }
  }
  @media (max-width: 940px) {
    padding: 0 3%;
    .bottomSection {
      .item {
        width: 300px;
      }
    }
  }
  @media (max-width: 740px) {
    padding: 0 7%;
    .bottomSection {
      gap: 5em;
      .item {
        width: 100%;
        text-align: center;
        align-items: center;
      }
    }
    .topSection {
      h1 {
        font-size: 2.7em;
      }
    }
  }
  @media (max-width: 672px) {
    .topSection {
      flex-direction: column;
      h1 {
        width: 100%;
      }
      p {
        width: 100%;
      }
    }
  }
  @media (max-width: 430px) {
    .topSection {
      flex-direction: column;
      h1 {
        font-size: 2em;
      }
      p {
        font-size: 14px;
      }
    }
  }
`;

export default Testimonials;
