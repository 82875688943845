import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const SEOTypes = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <motion.div
        className="item"
        ref={ref}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.3 }}
      >
        <h2>On-Page SEO</h2>
        <p>
          Refine pages for top search rankings by optimizing meta tags,
          headings, and content structure.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.6 }}
      >
        <h2>Off-Page SEO</h2>
        <p>
          Strengthen your online presence with expert link building, social
          media, and reputation management.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.9 }}
      >
        <h2>Technical SEO</h2>
        <p>
          Improve site performance for seamless user experiences with speed,
          mobile-friendliness, and proper indexing.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 1.2 }}
      >
        <h2>Local SEO</h2>
        <p>
          Dominate local search results through precise optimization, managing
          listings, reviews, and location keywords.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 1.5 }}
      >
        <h2>E-commerce SEO</h2>
        <p>
          Maximize product visibility for online stores, driving sales and
          increasing conversions effectively.
        </p>
      </motion.div>
      <motion.div
        className="item"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 1.8 }}
      >
        <h2>Enterprise SEO</h2>
        <p>
          Custom solutions for large websites, addressing complex site
          structures and intricate SEO challenges.
        </p>
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2em;
  .item {
    width: 340px;
    text-align: center;
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
      font-family: "Montserrat", cursive;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
      margin-top: 10px;
    }
  }
  @media (max-width: 1280px) {
    .item {
      width: 48%;
    }
  }
  @media (max-width: 945px) {
    .item {
      width: 98%;
    }
  }
`;

export default SEOTypes;
