import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FaBars, FaTimes } from "react-icons/fa";
import { BsMoonFill, BsFillSunFill } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";
import logo from "../assets/ASitesAgency Logo S-NB.png";
import logoDm from "../assets/ASitesAgency Logo S-D-NB.png";
import xmasLogoL from "../assets/xmasLogoL.png";
import xmasLogoD from "../assets/xmasLogoD.png";
import { motion, useAnimation, useInView } from "framer-motion";
import { useAuth } from "../context/AuthContext";

const Navbar = ({ theme, switchTheme }) => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);
  const [activeTab, setActiveTab] = useState("home");
  const [pos, setPos] = useState("top");
  const location = useLocation();
  const pathname = location.pathname;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const { currentUser } = useAuth();

  const animation1 = useAnimation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      // Clean up the event listener when the component unmounts
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const handleScroll = () => {
    let scrolled = window.scrollY;
    if (scrolled >= 5) {
      setPos("moved");
    } else {
      setPos("top");
    }
  };
  useEffect(() => {
    if (pathname === "/") {
      setActiveTab("home");
    } else if (pathname === "/services") {
      setActiveTab("services");
    } else if (pathname === "/packages") {
      setActiveTab("packages");
    } else if (pathname === "/about-us") {
      setActiveTab("about-us");
    } else if (pathname === "/contact-us") {
      setActiveTab("contact-us");
    } else if (pathname === "/sign-up") {
      setActiveTab("sign-up");
    }
  }, [pathname]);

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);

  return (
    <Container
      ref={ref}
      style={
        pos === "top"
          ? { background: "rgba(0, 0, 0, 0)" }
          : { background: "var(--trBackgroundColor)" }
      }
    >
      <Link to={"/"} className="link-styles">
        {theme === "dark" ? (
          <img className="logo" src={logoDm} alt="" />
        ) : (
          <img className="logo" src={logo} alt="" />
        )}
        {/* {theme === "dark" ? (
          <img className="logo" src={xmasLogoD} alt="" />
        ) : (
          <img className="logo" src={xmasLogoL} alt="" />
        )} */}
      </Link>
      <ul
        onClick={handleToggle}
        className={toggle ? "nav-menu active" : "nav-menu"}
      >
        <Link to={"/"} className="link-styles">
          <motion.li
            variants={{
              hidden: { opacity: 0, y: -70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{
              duration: 0.4,
              delay: 0.4,
              type: "spring",
              bounce: 2,
            }}
            className={activeTab === "home" ? "activeTab" : "nonActive"}
          >
            Home
          </motion.li>
        </Link>
        <Link to={"/services"} className="link-styles">
          <motion.li
            variants={{
              hidden: { opacity: 0, y: -70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{
              duration: 0.4,
              delay: 0.6,
              type: "spring",
              bounce: 2,
            }}
            className={activeTab === "services" ? "activeTab" : "nonActive"}
          >
            Services
          </motion.li>
        </Link>
        <Link to={"/packages"} className="link-styles">
          <motion.li
            variants={{
              hidden: { opacity: 0, y: -70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{
              duration: 0.4,
              delay: 0.8,
              type: "spring",
              bounce: 2,
            }}
            className={activeTab === "packages" ? "activeTab" : "nonActive"}
          >
            Packages
          </motion.li>
        </Link>
        <Link to={"/about-us"} className="link-styles">
          <motion.li
            variants={{
              hidden: { opacity: 0, y: -70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{
              duration: 0.4,
              delay: 1,
              type: "spring",
              bounce: 2,
            }}
            className={activeTab === "about-us" ? "activeTab" : "nonActive"}
          >
            About Us
          </motion.li>
        </Link>
        <Link to={"/contact-us"} className="link-styles">
          <motion.li
            variants={{
              hidden: { opacity: 0, y: -70 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{
              duration: 0.4,
              delay: 1.2,
              type: "spring",
              bounce: 2,
            }}
            className={activeTab === "contact-us" ? "activeTab" : "nonActive"}
          >
            Contact Us
          </motion.li>
        </Link>
        {currentUser ? (
          <Link to={"/account"} className="link-styles">
            {/* <motion.span
              variants={{
                hidden: { opacity: 0, y: -70 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={animation1}
              transition={{
                duration: 0.4,
                delay: 1.5,
                type: "spring",
                bounce: 2,
              }}
              className={activeTab === "account" ? "activeTab" : "nonActive"}
            > */}
            <BiUserCircle className="userBtn" />
            {/* </motion.span> */}
          </Link>
        ) : (
          <Link to={"/sign-up"} className="link-styles">
            {/* <motion.button
              variants={{
                hidden: { opacity: 0, y: -70 },
                visible: { opacity: 1, y: 0 },
              }}
              initial="hidden"
              animate={animation1}
              transition={{
                duration: 0.4,
                delay: 1.5,
                type: "spring",
                bounce: 2,
              }}
              className={activeTab === "sign-up" ? "activeTab" : "nonActive"}
            >
              Sign Up
            </motion.button> */}
            <button>Sign up</button>
          </Link>
        )}

        <div className="toggleTheme" onClick={switchTheme}>
          {theme === "dark" ? (
            <BsFillSunFill style={{ color: "white" }} className="themeIcon" />
          ) : (
            <BsMoonFill style={{ color: "#5e5c5c" }} className="themeIcon" />
          )}
        </div>
      </ul>
      <div className="mobile-menu" onClick={handleToggle}>
        {toggle ? <FaTimes className="icon" /> : <FaBars className="icon" />}
      </div>
    </Container>
  );
};

const Container = styled.div`
  backdrop-filter: blur(7px);
  color: var(--fontPrimaryColor);
  background: var(--trBackgroundColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 4%;
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  z-index: 100;
  overflow-x: visible;
  transition: 0.2s;
  .logo {
    height: 50px;
    margin-top: 7px;
  }
  .nav-menu {
    display: flex;
    gap: 37px;
    list-style: none;
    align-items: center;
    li {
      transition: 0.3s;
      cursor: pointer;
      :hover {
        color: var(--primaryColor);
      }
    }
    button {
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 7px 14px;
      font-size: 18px;
      border-radius: 20px;
      font-family: "Poppins", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
    .activeTab {
      color: var(--primaryColor);
      font-weight: 600;
    }
    .userBtn {
      font-size: 1.8em;
      margin-top: 8px;
      color: var(--fontSecondaryColor);
      cursor: pointer;
      transition: 0.2s;
      :hover {
        color: var(--primaryColor);
      }
    }
  }
  .themeIcon {
    font-size: 25px;
    margin-top: 7px;
    transition: 0.3s;
    cursor: pointer;
    :hover {
      color: var(--primaryColor);
    }
  }
  .mobile-menu {
    position: absolute;
    right: 5%;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;
    display: none;
    margin-top: 7px;
    :hover {
      transform: scale(1.1);
    }
  }
  @media (max-width: 1004px) {
    .nav-menu {
      color: var(--fontPrimaryColor);
      background: var(--primaryBackgroundColor);
      width: 90%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      padding: 10%;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0%;
      right: -100%;
      transition: 0.3s;
      z-index: 99;
      border-left: 1px solid var(--fontSecondaryColor);
    }
    .mobile-menu {
      display: block;
      z-index: 99;
    }
    .nav-menu.active {
      right: 0%;
    }
  }
  @media (max-width: 370px) {
    .nav-menu {
      width: 98%;
    }
  }
`;

export default Navbar;
