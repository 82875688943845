import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import webDesignImg from "../../assets/servicesPageAssets/webDesignImg.png";
import SEOimg from "../../assets/servicesPageAssets/SEOimg.png";

const TheServicesComponent = () => {
  return (
    <Container>
      <div className="services">
        <div className="service">
          <img src={webDesignImg} alt="" />
          <div>
            <Link to={"/services/web-services"} className="link-styles">
              <h2>Web Services</h2>
            </Link>
            <p>
              Elevate your online presence with our web design and development
              expertise. We create user-friendly, visually stunning websites
              that drive results. Our team is committed to enhancing your
              digital identity and engaging your audience effectively.
            </p>
            <span>
              <h3>Experience:</h3>
              <h4>4+ Years</h4>
            </span>
            <div className="btns">
              <Link to={"/services/web-services"}>
                <button>DISCOVER MORE</button>
              </Link>
            </div>
            <div className="keywords">
              <p>#Modern</p>
              <p>#Professional</p>
              <p>#Responsive</p>
              <p>#DynamicTool</p>
            </div>
          </div>
        </div>
        <div className="service reversed">
          <img src={SEOimg} alt="" />
          <div>
            <h2>SEO (Search Engine Optimization)</h2>
            <p>
              Boost online visibility with our SEO expertise. We optimize
              content, keywords, and strategies to increase organic traffic and
              rankings. Trust our proven methods to outshine competitors and
              drive business growth.
            </p>
            <span>
              <h3>Experience:</h3>
              <h4>2+ Years</h4>
            </span>
            <Link to={"/services/seo-services"} className="link-styles">
              <div className="btns">
                <button>DISCOVER MORE</button>
              </div>
            </Link>
            <div className="keywords">
              <p>#traffic</p>
              <p>#topResults</p>
              <p>#clients</p>
              <p>#localSEO</p>
            </div>
          </div>
        </div>
        <div className="service">
          <img
            src="https://static.vecteezy.com/system/resources/previews/011/064/686/original/social-media-and-digital-marketing-3d-illustration-free-png.png"
            alt=""
          />
          <div>
            <h2>Digital Marketing</h2>
            <p>
              Connect with your ideal audience through our digital marketing
              strategies. Benefit from increased brand awareness, customer
              engagement, and lead generation. We create targeted campaigns that
              make you stand out in the digital landscape.
            </p>
            <span>
              <h3>Experience:</h3>
              <h4>4+ Years</h4>
            </span>
            <Link to={"/services/digital-marketing"} className="link-styles">
              <div className="btns">
                <button>DISCOVER MORE</button>
              </div>
            </Link>
            <div className="keywords">
              <p>#digitalMarketing</p>
              <p>#marketing</p>
              <p>#ads</p>
              <p>#socialMediaAds</p>
            </div>
          </div>
        </div>
        <div className="service reversed">
          <img
            src="https://static.vecteezy.com/system/resources/previews/016/617/879/original/professional-video-editor-3d-illustration-png.png"
            alt=""
          />
          <div>
            <h2>Content Creation & Copywriting</h2>
            <p>
              Engage and convert with our persuasive content. Our words tell
              your story, captivate your audience, and drive action. Trust us to
              craft compelling articles, blog posts, and copy that boost your
              online presence and customer base.
            </p>
            <span>
              <h3>Experience:</h3>
              <h4>2+ Years</h4>
            </span>
            <Link
              to={"/services/content-creation-services"}
              className="link-styles"
            >
              <div className="btns">
                <button>DISCOVER MORE</button>
              </div>
            </Link>
            <div className="keywords">
              <p>#contentCreation</p>
              <p>#videoEditing</p>
              <p>#posts</p>
              <p>#contentCopywriting</p>
            </div>
          </div>
        </div>
        <div className="service ">
          <img
            src="https://static.vecteezy.com/system/resources/previews/012/794/016/original/3d-illustration-of-add-image-on-ui-design-view-png.png"
            alt=""
          />
          <div>
            <h2>Brand Identity Design</h2>
            <p>
              Stand out and be memorable with our branding design. We create
              captivating logos and visuals that define your unique identity.
              Trust us to leave a lasting impression, elevating your brand in
              the digital world.
            </p>
            <span>
              <h3>Experience:</h3>
              <h4>2+ Years</h4>
            </span>
            <Link to={"/services/branding-services"} className="link-styles">
              <div className="btns">
                <button>DISCOVER MORE</button>
              </div>
            </Link>
            <div className="keywords">
              <p>#brand</p>
              <p>#brandDesign</p>
              <p>#brandIdenity</p>
              <p>#brandDevelpment</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 0 7%;
  padding-bottom: 7em;
  background: var(--primaryBackgroundColor);
  .services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7em;
    .service {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3em;
      height: 80vh;
      position: sticky;
      top: 3em;
      background: var(--trBackgroundColor);
      backdrop-filter: blur(20px);
      width: 100%;
      padding: 20px;
      div {
        display: flex;
        flex-direction: column;
        gap: 1em;
        h2 {
          font-weight: 600;
          line-height: 1.4em;
          font-family: "Montserrat", cursive;
          background: var(--gradientBackground);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        p {
          font-family: "Comfortaa", cursive;
          line-height: 24px;
          color: var(--fontSecondaryColor);
        }
        span {
          display: flex;
          align-items: center;
          gap: 7px;
          h3 {
            font-family: "Montserrat", cursive;
            background: var(--gradientBackground);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          h4 {
            font-weight: 400;
          }
        }
        .btns {
          display: flex;
          flex-direction: row;
          gap: 7px;
          button {
            padding: 10px 14px;
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            border: none;
            background: var(--fontPrimaryColor);
            color: var(--fontPrimaryColorOpp);
            cursor: pointer;
            :nth-child(2) {
              background: none;
              color: var(--fontPrimaryColor);
              border-bottom: 2px solid var(--fontPrimaryColor);
              :hover {
                background-color: var(--secondaryBackgroundColor);
              }
            }
            :hover {
              background: var(--primaryColor);
              transition: 0.3s;
            }
          }
        }
        .keywords {
          flex-direction: row;
          gap: 7px;
          flex-wrap: wrap;
          p {
            padding: 4px 10px;
            background-color: var(--secondaryBackgroundColor);
            border-radius: 1em;
          }
        }
      }
      img {
        width: 50%;
        max-width: 700px;
      }
    }
    .reversed {
      flex-direction: row-reverse;
    }
  }
  @media (min-width: 2000px) {
    .services {
      .service {
        img {
          width: 100em;
        }
        div {
          h2 {
            font-size: 3em;
          }
          p {
            font-size: 27px;
            line-height: 1.3em;
          }
          h3 {
            font-size: 27px;
          }
          h4 {
            font-size: 27px;
          }
          .btns {
            button {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .services {
      .service {
        img {
          width: 470px;
        }
      }
    }
  }
  @media (max-width: 970px) {
    .services {
      .service {
        img {
          width: 400px;
        }
      }
    }
  }
  @media (max-width: 940px) {
    .services {
      .service {
        top: 2em;
        flex-direction: column;
        height: 100vh;

        gap: 10px;
        div {
          gap: 10px;
          text-align: center;
          align-items: center;
          .keywords {
            gap: 7px;
            justify-content: center;
          }
        }
        img {
          width: 340px;
        }
      }
    }
  }
  @media (max-width: 580px) {
    padding: 0 2%;
    .services {
      .service {
        img {
          width: 70%;
        }
        div {
          .keywords {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .services {
      .service {
        padding: 4px;
        img {
          width: 80%;
        }
        div {
          h2 {
            font-size: 1.2em;
          }
          p {
            line-height: 1.2em;
          }
        }
      }
    }
  }
`;

export default TheServicesComponent;
