const data = {
  silverWebPack: [
    {
      included: [
        "Up to 5 pages",
        "2-7 sections per page",
        "100% Responsive",
        "Simple Modern Design",
        "Basic SEO",
        "Basic Animations",
        "Free domain",
        "2 weeks Free Maintenance",
        "SSL Certified",
        "Contact Form",
        "4-8 Days Delivery",
      ],
      excluded: [
        "Booking System",
        "Analytics and Reports",
        "User Authentication",
        "Blog Setup",
        "eCommerce Functionality",
        "AI Integration",
        "Multilingual",
        "Social Media Marketing",
        "Admin Dashboard",
        "Email Marketing Automation",
        "Help Center Development",
        "AI Chatbots",
      ],
    },
  ],
  goldWebPack: [
    {
      included: [
        "Up to 10 pages",
        "3-10 sections per page",
        "100% Responsive",
        "Booking System",
        "Professional Modern Design",
        "Advanced Local SEO",
        "Cool Advanced Animations",
        "Analytics and Reports",
        "Free domain",
        "1 month Free Maintenance",
        "SSL Certified",
        "Social Media Marketing",
        "Contact Form",
        "Multilingual",
        "2-3 Weeks Delivery",
        "User Authentication",
        "Blog Setup",
        "eCommerce Functionality",
      ],
      excluded: [
        "AI Integration",
        "Admin Dashboard",
        "Email Marketing Automation",
        "Help Center Development",
        "AI Chatbots",
      ],
    },
  ],
  diamondWebPack: [
    {
      included: [
        "Up to 30 pages",
        "3-20 sections per page",
        "100% Responsive",
        "Booking System",
        "AI Integration",
        "Professional Modern Design",
        "Admin Dashboard",
        "Advanced International SEO",
        "Cool Modern Animations & Effects",
        "Analytics and Reports",
        "Free domain",
        "2 month Free Maintenance",
        "SSL Certified",
        "Help Center Development",
        "AI Chatbots",
        "Social Media Marketing",
        "Contact Form",
        "Multilingual",
        "1-3 Months Delivery",
        "User Authentication",
        "Email Marketing Automation",
        "Blog Setup",
        "eCommerce Functionality",
      ],
      excluded: [],
    },
  ],
  silverDMPack: [
    {
      included: [
        "Social Media Management",
        "Basic Content Creation",
        "Basic SEO Optimization",
        "Social Media Advertising",
        "Monthly Analytics Report",
        "Free Consultations",
      ],
    },
  ],
  goldDMPack: [
    {
      included: [
        "Advanced Social Media Management",
        "Comprehensive SEO Services",
        "Content Marketing",
        "Email Marketing Automation",
        "Advanced Social Media Advertising",
        "Conversion Optimization",
        "Monthly Analytics Report",
        "Free Consultations",
        "Quarterly Marketing Strategy Review",
      ],
    },
  ],
  diamondDMPack: [
    {
      included: [
        "Full-Scale Social Media Dominance",
        "Complete SEO Domination",
        "Content Mastery",
        "Holistic Email Marketing",
        "Elite Social Media Advertising",
        "Conversion Optimization",
        "Comprehensive Conversion Strategies",
        "Conversion Optimization",
        "Monthly Analytics Report",
        "Priority Support and Consultation",
        "Quarterly Marketing Strategy Review",
        "Exclusive Access to Beta Features",
      ],
    },
  ],
  silverCCPack: [
    {
      included: [
        "Blog Posts",
        "Website Copy",
        "Social Media Copy",
        "Email Content",
        "Basic Keyword Integration",
        "Client Briefing Meeting",
      ],
    },
  ],
  goldCCPack: [
    {
      included: [
        "Extended Blog Posts",
        "Full Website Copy",
        "Social Media Content Calendar",
        "Email Marketing Copy",
        "Advanced Keyword Strategy",
        "Monthly Strategy Call",
      ],
    },
  ],
  diamondCCPack: [
    {
      included: [
        "Premium Blog Content",
        "Comprehensive Website Overhaul",
        "Strategic Social Media Management",
        "Email Marketing Mastery",
        "Authority Building Content",
        "SEO-Driven Content",
        "Custom Content Workshops",
        "Quarterly Content Performance Review",
      ],
    },
  ],
  silverBrandingPack: [
    {
      included: [
        "Brand Identity Design",
        "Basic Brand Style Guide",
        "Business Card Design",
        "Social Media Profile Imagery",
        "Brand Consultation Meeting",
        "10 Cool Mockups",
      ],
    },
  ],
  goldBrandingPack: [
    {
      included: [
        "Extended Logo Revisions",
        "Comprehensive Brand Style Guide",
        "Stationery Design",
        "Brand Messaging Guide",
        "Social Media Branding",
        "Brand Launch Strategy Session",
        "30 Modern Realistic Mockups",
        "Brand Consultation Meeting",
      ],
    },
  ],
  diamondBrandingPack: [
    {
      included: [
        "Extended Logo Revisions",
        "Brand Photography Session",
        "Custom Branded Merchandise Design",
        "Comprehensive Brand Campaign",
        "Custom Website Branding",
        "Advanced Social Media Branding",
        "Brand Launch Event Coordination",
        "Advanced Brand Consultation",
        "Brand Performance Metrics",
        "50 Modern Realistic Mockups",
        "Brand Consultation Meeting",
      ],
    },
  ],
};
export default data;
