import React, { useState } from "react";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
import data from "./packagesData";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const WebsitePackages = () => {
  const { silverWebPack, goldWebPack, diamondWebPack } = data;
  return (
    <Container>
      <h2 className="title">WEBSITE PACKAGES</h2>
      <div className="packs">
        <div className="pack silver">
          <h2>SILVER PACK</h2>
          {silverWebPack.map((item) => (
            <div className="items">
              {item.included &&
                Array.isArray(item.included) &&
                item.included.map((feature, index) => (
                  <span>
                    <FaCheckCircle className="icon" />
                    <h3 className="included" key={index}>
                      {feature}
                    </h3>
                  </span>
                ))}
              {item.excluded &&
                Array.isArray(item.excluded) &&
                item.excluded.map((feature, index) => (
                  <span>
                    <FaCheckCircle className="iconR" />
                    <h3 className="included" key={index}>
                      {feature}
                    </h3>
                  </span>
                ))}
            </div>
          ))}
          <Link to={"/get-a-proposal"} className="link-styles">
            <button>REQUEST AN OFFER</button>
          </Link>{" "}
        </div>
        <div className="pack gold">
          <h2>GOLD PACK</h2>
          <span className="mp">MOST POPULAR</span>
          {goldWebPack.map((item) => (
            <div className="items">
              {item.included &&
                Array.isArray(item.included) &&
                item.included.map((feature, index) => (
                  <span>
                    <FaCheckCircle className="icon" />
                    <h3 className="included" key={index}>
                      {feature}
                    </h3>
                  </span>
                ))}
              {item.excluded &&
                Array.isArray(item.excluded) &&
                item.excluded.map((feature, index) => (
                  <span>
                    <FaCheckCircle className="iconR" />
                    <h3 className="included" key={index}>
                      {feature}
                    </h3>
                  </span>
                ))}
            </div>
          ))}
          <Link to={"/get-a-proposal"} className="link-styles">
            <button>REQUEST AN OFFER</button>
          </Link>{" "}
        </div>
        <div className="pack diamond">
          <h2>DIAMOND PACK</h2>
          {diamondWebPack.map((item) => (
            <div className="items">
              {item.included &&
                Array.isArray(item.included) &&
                item.included.map((feature, index) => (
                  <span>
                    <FaCheckCircle className="icon" />
                    <h3 className="included" key={index}>
                      {feature}
                    </h3>
                  </span>
                ))}
              {item.excluded &&
                Array.isArray(item.excluded) &&
                item.excluded.map((feature, index) => (
                  <span>
                    <FaCheckCircle className="iconR" />
                    <h3 className="included" key={index}>
                      {feature}
                    </h3>
                  </span>
                ))}
            </div>
          ))}
          <Link to={"/get-a-proposal"} className="link-styles">
            <button>REQUEST AN OFFER</button>
          </Link>{" "}
        </div>
      </div>
      <Swiper
        className="swiper"
        navigation={true}
        modules={[Navigation]}
        spaceBetween={50}
        slidesPerView={1}
      >
        <SwiperSlide className="packs slide">
          <div className="pack silver">
            <h2>SILVER PACK</h2>
            {silverWebPack.map((item) => (
              <div className="items">
                {item.included &&
                  Array.isArray(item.included) &&
                  item.included.map((feature, index) => (
                    <span>
                      <FaCheckCircle className="icon" />
                      <h3 className="included" key={index}>
                        {feature}
                      </h3>
                    </span>
                  ))}
                {item.excluded &&
                  Array.isArray(item.excluded) &&
                  item.excluded.map((feature, index) => (
                    <span>
                      <FaCheckCircle className="iconR" />
                      <h3 className="included" key={index}>
                        {feature}
                      </h3>
                    </span>
                  ))}
              </div>
            ))}
            <Link to={"/get-a-proposal"} className="link-styles">
              <button>REQUEST AN OFFER</button>
            </Link>{" "}
          </div>
        </SwiperSlide>
        <SwiperSlide className="packs slide">
          <div className="pack gold">
            <h2>GOLD PACK</h2>
            {goldWebPack.map((item) => (
              <div className="items">
                {item.included &&
                  Array.isArray(item.included) &&
                  item.included.map((feature, index) => (
                    <span>
                      <FaCheckCircle className="icon" />
                      <h3 className="included" key={index}>
                        {feature}
                      </h3>
                    </span>
                  ))}
                {item.excluded &&
                  Array.isArray(item.excluded) &&
                  item.excluded.map((feature, index) => (
                    <span>
                      <FaCheckCircle className="iconR" />
                      <h3 className="included" key={index}>
                        {feature}
                      </h3>
                    </span>
                  ))}
              </div>
            ))}
            <Link to={"/get-a-proposal"} className="link-styles">
              <button>REQUEST AN OFFER</button>
            </Link>{" "}
          </div>
        </SwiperSlide>
        <SwiperSlide className="packs slide">
          <div className="pack diamond">
            <h2>DIAMOND PACK</h2>
            {diamondWebPack.map((item) => (
              <div className="items">
                {item.included &&
                  Array.isArray(item.included) &&
                  item.included.map((feature, index) => (
                    <span>
                      <FaCheckCircle className="icon" />
                      <h3 className="included" key={index}>
                        {feature}
                      </h3>
                    </span>
                  ))}
                {item.excluded &&
                  Array.isArray(item.excluded) &&
                  item.excluded.map((feature, index) => (
                    <span>
                      <FaCheckCircle className="iconR" />
                      <h3 className="included" key={index}>
                        {feature}
                      </h3>
                    </span>
                  ))}
              </div>
            ))}
            <Link to={"/get-a-proposal"} className="link-styles">
              <button>REQUEST AN OFFER</button>
            </Link>{" "}
          </div>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 0%;
  background-image: linear-gradient(
      var(--primaryBackgroundColor) 2%,
      rgba(19, 17, 23, 0) 20%
    ),
    radial-gradient(
      circle farthest-side at -25% 150%,
      var(--primaryBackgroundColor) 8%,
      rgba(13, 17, 23, 0) 65%
    ),
    radial-gradient(
      circle at -25% -125%,
      rgba(13, 17, 23, 0) 50%,
      var(--trBackgroundColor) 70%,
      rgba(13, 17, 23, 0) 72%
    ),
    radial-gradient(circle at 0 -50%, #ccafe7 42%, rgba(255, 255, 255, 0) 62%),
    radial-gradient(
      circle farthest-side at 0 -25%,
      #6842c2 60%,
      rgba(13, 17, 23, 0) 72%
    ),
    radial-gradient(circle at 60% -80%, #05f 26%, rgba(13, 17, 23, 0) 72%);
  .title {
    text-align: center;
    font-size: 4vw;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
  }
  .packs {
    display: flex;
    justify-content: center;
    gap: 2em;
    flex-wrap: wrap;
    margin-top: 2em;
    padding: 0 7%;
    .gold {
      .mp {
        padding: 10px;
        background-color: var(--primaryBackgroundColor);
        width: 140px;
        right: 0;
        position: absolute;
        border-radius: 20px 0 0 20px;
      }
      .items {
        margin-top: 3em;
      }
    }
    .pack {
      width: 30%;
      border-radius: 30px;
      backdrop-filter: blur(4px);
      background-color: var(--trBackgroundColor);
      position: relative;
      h2 {
        font-weight: 300;
        margin: 1em 0;
        text-align: center;
      }
      button {
        padding: 14px 18px;
        font-family: "Montserrat", sans-serif;
        font-size: 18px;
        border: none;
        background: var(--secondaryColor);
        border-radius: 20px;
        color: white;
        margin: auto;
        display: flex;
        cursor: pointer;
      }
      .items {
        padding: 1em 2em;
        display: flex;
        flex-direction: column;
        gap: 10px;
        span {
          display: flex;
          align-items: center;
          gap: 10px;
          h3 {
            font-weight: 300;
            line-height: 20px;
          }
          .icon {
            color: #0eb90e;
            font-size: 1em;
          }
          .iconR {
            color: #c24545;
            font-size: 1em;
          }
        }
      }
    }
  }
  .swiper {
    display: none;
  }
  @media (min-width: 1500px) {
    .packs {
      .pack {
        .items {
          h3 {
            font-size: 1.3vw;
          }
        }
      }
    }
  }
  @media (max-width: 1300px) {
    .packs {
      padding: 0 1%;
      gap: 1em;
    }
  }
  @media (max-width: 1000px) {
    background-image: linear-gradient(
        var(--primaryBackgroundColor) 2%,
        rgba(19, 17, 23, 0) 20%
      ),
      radial-gradient(
        circle farthest-side at -25% 150%,
        var(--primaryBackgroundColor) 8%,
        rgba(13, 17, 23, 0) 65%
      ),
      radial-gradient(
        circle at -25% -125%,
        rgba(13, 17, 23, 0) 50%,
        var(--trBackgroundColor) 70%,
        rgba(13, 17, 23, 0) 72%
      ),
      radial-gradient(circle at 0 -50%, #ccafe7 42%, rgba(255, 255, 255, 0) 62%),
      radial-gradient(
        circle farthest-side at 0 -25%,
        #a485eb 60%,
        rgba(13, 17, 23, 0) 72%
      ),
      radial-gradient(circle at 60% -80%, #05f 26%, rgba(13, 17, 23, 0) 72%);
    .title {
      font-size: 7vw;
    }
    .packs {
      flex-wrap: wrap;
      .pack {
        width: 48%;
      }
    }
  }
  @media (max-width: 700px) {
    .packs {
      display: none;
    }
    .swiper {
      display: block;
      padding: 0 4% 0 11%;
      .packs {
        display: block;
        .pack {
          padding-top: 1em;
          width: 90%;
        }
      }
    }
  }
  @media (max-width: 500px) {
    .swiper {
      padding: 0 2% 0 4%;
      .packs {
        .pack {
          width: 99%;
        }
      }
    }
  }
`;

export default WebsitePackages;
