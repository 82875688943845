import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import dots from "../assets/homePageAssets/dots.png";
import CCWhyChooseUs from "../components/servicesComponents/CCWhyChooseUs";
import CCServicesComp from "../components/servicesComponents/CCServicesComp";
import CWServicesComp from "../components/servicesComponents/CWServicesComp";
import CCFAQ from "../components/servicesComponents/CCFAQ";

const ContentCreationServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Content Creation & Copywriting Services - ASites Agency";
  }, []);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="banner">
        <div className="details">
          <img
            src={dots}
            className="dots"
            alt="ASites Agency Content Creation Services Page Image"
          />
          <motion.h1
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: 200 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 0.2,
              type: "spring",
            }}
          >
            <span>Words</span> That Connect, <br /> <span>Content</span> That
            Converts.
          </motion.h1>
          <motion.p
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 1,
            }}
          >
            We craft compelling content and persuasive copy that resonates,
            informs, and drives results.
          </motion.p>
          <div className="tags">
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.3,
              }}
            >
              Writing
            </motion.h3>
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.6,
              }}
            >
              Creativity
            </motion.h3>
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.9,
              }}
            >
              Editing
            </motion.h3>
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 2.2,
              }}
            >
              Copy
            </motion.h3>
          </div>
        </div>
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/video-content-6008582-4995381.png"
          alt=""
        />
      </div>
      <CCWhyChooseUs />
      <CCServicesComp />
      <CWServicesComp />
      <CCFAQ />
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 0%;
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  .banner {
    padding: 1em 7%;
    background: var(--backgroundGradient2M);
    background-size: 30% 67%, 100% 100%, 97% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    .details {
      position: relative;
      .dots {
        position: absolute;
        width: 190px;
        top: -3em;
        opacity: 0.08;
      }
      h1 {
        font-size: 3.4em;
        font-weight: 500;
        line-height: 1.2em;
        span {
          color: var(--primaryColor);
        }
      }
      p {
        font-weight: 300;
        color: var(--fontSecondaryColor);
        margin-top: 10px;
      }
      .tags {
        display: flex;
        align-items: center;
        margin-top: 10px;
        gap: 1em;
        h3 {
          font-weight: 400;
          padding: 4px 10px;
          background: var(--secondaryBackgroundColor);
          border-radius: 20px;
        }
      }
    }
    img {
      width: 45%;
      max-width: 800px;
    }
  }
  @media (max-width: 1230px) {
    .banner {
      padding: 3em 4%;
    }
  }
  @media (max-width: 1150px) {
    .banner {
      padding: 1em 4%;
      .details {
        h1 {
          font-size: 3em;
        }
      }
    }
  }
  @media (max-width: 1065px) {
    .banner {
      gap: 1em;
      img {
        width: 40%;
      }
    }
  }
  @media (max-width: 945px) {
    .banner {
      .details {
        h1 {
          font-size: 2.7em;
        }
      }
    }
  }
  @media (max-width: 855px) {
    .banner {
      flex-direction: column;
      .details {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        .dots {
          width: 150px;
        }
      }
      img {
        width: 70%;
      }
    }
  }
  @media (max-width: 450px) {
    .banner {
      gap: 0;
      .details {
        h1 {
          font-size: 2em;
        }
        .tags {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      img {
        width: 80%;
      }
    }
  }
`;

export default ContentCreationServices;
