import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const CCWhyChooseUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{
          duration: 0.7,
          delay: 0.2,
        }}
        className="title"
      >
        <h2>Why Choose Us</h2>
        <p>
          Here are some reasons why you should choose ASites <br /> for you
          Content Creation and Copywriting.
        </p>
      </motion.div>
      <div className="reasons">
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 0.6,
          }}
          ref={ref}
          className="cc"
        >
          <h2>For Content Creation</h2>
          <span>
            <hr />
            <h3>Compelling Social Media</h3>
            <p>
              We create shareable content that sparks conversations, increases
              followers, and amplifies your social presence.
            </p>
          </span>
          <span>
            <hr />
            <h3>Visual Storytelling</h3>
            <p>
              Our content includes striking visuals and multimedia elements to
              convey your message vividly.
            </p>
          </span>
          <span>
            <hr />
            <h3>Proven Impact</h3>
            <p>
              Our content have delivered tangible results for countless clients.
              With us, your brand's story becomes a powerful driver of success.
            </p>
          </span>
        </motion.div>
        <hr className="divider" />
        <motion.div
          className="cw"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 1,
          }}
        >
          <h2>For Copywriting</h2>
          <span>
            <hr />
            <h3>Website Copy</h3>
            <p>
              Your website copy is your brand's voice. We ensure it conveys your
              message clearly, engaging visitors and boosting conversions.
            </p>
          </span>
          <span>
            <hr />
            <h3>Persuasive Ad Copy</h3>
            <p>
              We specialize in creating ad copy that compels action, whether
              it's making a purchase, signing up, or engaging with your brand.
            </p>
          </span>
          <span>
            <hr />
            <h3>Sales Letters</h3>
            <p>
              We write compelling sales letters that convert prospects into
              loyal customers through persuasive messaging.
            </p>
          </span>
        </motion.div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
  .reasons {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    gap: 2em;
    .divider {
      height: 20em;
    }
    .cc,
    .cw {
      h2 {
        font-weight: 500;
        text-align: center;
        margin-bottom: 1em;
      }
      span {
        text-align: end;
        width: 500px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        hr {
          width: 100%;
        }
        h3 {
          text-align: end;
          font-weight: 400;
          color: var(--primaryColor);
        }
        p {
          color: var(--fontSecondaryColor);
          font-weight: 300;
        }
      }
    }
    .cw {
      span {
        text-align: start;
        align-items: flex-start;
        h3 {
          text-align: start;
        }
      }
    }
  }
  @media (max-width: 1120px) {
    .reasons {
      .cc,
      .cw {
        gap: 2em;
        span {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 800px) {
    .reasons {
      gap: 1em;
    }
  }
  @media (max-width: 500px) {
    .reasons {
      flex-direction: column;
      gap: 1em;
      .divider {
        display: none;
      }
    }
  }
`;

export default CCWhyChooseUs;
