import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const DMWhyChooseUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="title">
        <h2>Why Choose Us</h2>
        <p>
          We understand that the digital landscape is ever-evolving, <br /> and
          your success hinges on staying ahead of the curve. <br /> Here's why
          we stand out.
        </p>
      </div>
      <div className="reasons">
        <motion.div
          className="reason"
          ref={ref}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 0.4,
          }}
        >
          <hr />
          <h2>Proven Expertise</h2>
          <p>
            With a track record of success, we're seasoned experts in the
            digital marketing realm. We bring years of experience and a
            portfolio of accomplishments to the table.
          </p>
        </motion.div>
        <motion.div
          className="reason"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 0.7,
          }}
        >
          <hr />
          <h2>Customized Strategies</h2>
          <p>
            We don't believe in one-size-fits-all solutions. Every client's
            needs are unique, and we craft tailored strategies that address your
            specific goals and challenges.
          </p>
        </motion.div>
        <motion.div
          className="reason"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 1,
          }}
        >
          <hr />
          <h2>Data-Driven Decisions</h2>
          <p>
            Our approach is fueled by data and analytics. We make informed
            decisions, ensuring that every campaign is optimized for success.
          </p>
        </motion.div>
        <motion.div
          className="reason"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 1.3,
          }}
        >
          <hr />
          <h2>Transparent Communication</h2>
          <p>
            We value transparency in our partnerships. Our open lines of
            communication ensure you're kept informed at every stage of your
            campaign.
          </p>
        </motion.div>
        <motion.div
          className="reason"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 1.6,
          }}
        >
          <hr />
          <h2>Exceptional Client Care</h2>
          <p>
            We're not just partners; we're your digital advocates. Our
            commitment to your success is unwavering, and your satisfaction is
            our top priority.
          </p>
        </motion.div>
        <motion.div
          className="reason"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 1.9,
          }}
        >
          <hr />
          <h2>Transparent Communication</h2>
          <p>
            We value transparency in our partnerships. Our open lines of
            communication ensure you're kept informed at every stage of your
            campaign.
          </p>
        </motion.div>
        <motion.div
          className="reason"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 2.2,
          }}
        >
          <hr />
          <h2>Continuous Evolution</h2>
          <p>
            The digital world never stops changing, and neither do we. We stay
            ahead of the latest trends and algorithm updates to ensure you're
            always at the forefront of your industry.
          </p>
        </motion.div>
        <motion.div
          className="reason"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 2.5,
          }}
        >
          <hr />
          <h2>Measurable Results</h2>
          <p>
            Our success is quantifiable. We provide clear, regular reports, so
            you can see the impact of our work and the growth of your brand.
          </p>
        </motion.div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  .title {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
  .reasons {
    display: flex;
    justify-content: center;
    gap: 3em 2em;
    flex-wrap: wrap;
    margin-top: 2em;
    .reason {
      width: 340px;
      text-align: center;
      hr {
        border: none;
        border-bottom: 1px solid var(--primaryColor);
      }
      h2 {
        font-weight: 400;
        color: var(--primaryColor);
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
    }
  }
  @media (max-width: 1180px) {
    .reasons {
      .reason {
        width: 270px;
      }
    }
  }
  @media (max-width: 985px) {
    .reasons {
      .reason {
        width: 250px;
      }
    }
  }
  @media (max-width: 885px) {
    .reasons {
      .reason {
        width: 44%;
      }
    }
  }
  @media (max-width: 550px) {
    .reasons {
      .reason {
        width: 90%;
        text-align: left;
      }
    }
  }
`;

export default DMWhyChooseUs;
