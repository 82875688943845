import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const OurProcess = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);

  return (
    <Container>
      <div className="title">
        <h1>
          Our <br /> <span>6-Step Process</span>
        </h1>
        <p>
          Our six-step process lets you rest easy <br /> and focus on what’s
          important: your business.
        </p>
      </div>
      <div className="items" ref={ref}>
        <motion.div
          className="item"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/search-with-hand-6753233-5528861.png?f=webp"
            alt=""
          />
          <h3>Step 1</h3>
          <h2>Research</h2>
        </motion.div>
        <motion.div
          className="item"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.6, type: "spring" }}
        >
          <img
            src="https://static.vecteezy.com/system/resources/previews/014/063/253/original/3d-question-mark-icon-questioning-for-answers-png.png"
            alt=""
          />
          <h3>Step 2</h3>
          <h2>Discovery</h2>
        </motion.div>
        <motion.div
          className="item"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 0.9, type: "spring" }}
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/content-management-8532910-6715479.png"
            alt=""
          />
          <h3>Step 3</h3>
          <h2>Content</h2>
        </motion.div>
        <motion.div
          className="item"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 1.2, type: "spring" }}
        >
          <img
            src="https://static.vecteezy.com/system/resources/previews/015/153/132/original/boost-startup-3d-icon-design-png.png"
            alt=""
          />
          <h3>Step 4</h3>
          <h2>Design</h2>
        </motion.div>
        <motion.div
          className="item"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 1.5, type: "spring" }}
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/web-coding-8877316-7311014.png"
            alt=""
          />
          <h3>Step 5</h3>
          <h2>Development</h2>
        </motion.div>
        <motion.div
          className="item"
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation}
          transition={{ duration: 0.4, delay: 1.8, type: "spring" }}
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/optimization-9062155-7479449.png"
            alt=""
          />
          <h3>Step 6</h3>
          <h2>Optimization</h2>
        </motion.div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  font-family: "Montserrat", cursive;
  background: var(--backgroundGradient2T);
  background-size: 40% 100%, 90% 140%, 49% 100%;
  background-repeat: no-repeat;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    h1 {
      line-height: 1.2em;
      font-weight: 400;
      span {
        color: var(--primaryColor);
      }
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
      text-align: center;
    }
  }
  .items {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4em;
    flex-wrap: wrap;
    .item {
      text-align: center;
      img {
        width: 80px;
      }
      h3 {
        font-weight: 300;
      }
      h2 {
        color: var(--primaryColor);
        font-family: "Montserrat", cursive;
        font-weight: 400;
      }
    }
  }
  @media (min-width: 1660px) {
    height: 54vh;
    .title {
      h1 {
        font-size: 3.7em;
      }
      p {
        font-size: 27px;
      }
    }
  }
  @media (min-width: 1440px) {
    .items {
      .item {
        width: 12%;
        img {
          width: 100%;
          max-width: 240px;
        }
        h3 {
          font-size: 23px;
        }
        h2 {
          font-size: 27px;
        }
      }
    }
  }
  @media (max-width: 750px) {
    background-size: 40% 100%, 100% 110%, 90% 110%;
  }
  @media (max-width: 600px) {
    .items {
      gap: 1em;
      .item {
        width: 40%;
        img {
          width: 110px;
        }
      }
    }
  }
  @media (max-width: 440px) {
    background-size: 100% 100%, 100% 110%, 130% 140%;
    .items {
      gap: 2em;
      .item {
        width: 44%;
        img {
          width: 70%;
        }
      }
    }
  }
`;

export default OurProcess;
