import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import emailjs from "@emailjs/browser";
import { FaCheckCircle } from "react-icons/fa";

const ContactUsComp = () => {
  const [alert, setAlert] = useState(false);
  const openCalendlyPopup = () => {
    window.open(
      "https://calendly.com/asitesagency/free-consultation",
      "calendlyPopup",
      "width=800,height=600"
    );
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);

  const sendEmail = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      emailjs
        .sendForm(
          "asites-agency-contact",
          "template_pr3ey4h",
          e.target,
          "-rXsp2xIJ98qOBflT"
        )
        .then((res) => {
          console.log(res);
          handleAlert();
        })
        .catch((err) => console.log(err));
    } else {
      console.log("Please fill in all fields.");
    }
  };

  const handleAlert = () => {
    setAlert(true);

    setTimeout(() => {
      setAlert(false);
    }, 2000);
  };

  return (
    <Container>
      {alert ? (
        <div className="alert">
          <FaCheckCircle className="icon" />
          <p>Message Sent!</p>
        </div>
      ) : (
        ""
      )}
      <motion.div
        variants={{
          hidden: { opacity: 0, x: -200 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.7, delay: 0.4, type: "spring" }}
        className="left"
        ref={ref}
      >
        <h1>
          We're one message away <br />
          <span> from transforming your business.</span>
        </h1>
        <p>
          Whether you're interested in our services, have a proposal in mind, or
          simply want to learn more about what we can do for your business,
          we're here to listen and assist.
        </p>
        <button onClick={openCalendlyPopup}>BOOK A FREE CALL</button>
      </motion.div>
      <motion.form
        onSubmit={sendEmail}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.7, delay: 0.8 }}
      >
        <h2>Start a concersation with us</h2>
        <input type="text" name="name" placeholder="Full Name" required />
        <input
          type="text"
          name="companyName"
          placeholder="Company Name"
          required
        />
        <input type="email" name="email" placeholder="Email Address" required />
        {/* <input
          type="file"
          name="attachment"
          placeholder="Upload a file (Optional)"
          onChange={(e) => handleFileChange(e)}
        /> */}
        <input
          type="phone"
          name="phoneNumber"
          placeholder="Phone Number (Optional)"
        />
        <textarea
          cols="20"
          rows="4"
          name="message"
          placeholder="Your Message"
          required
        ></textarea>
        <button type="submit">SUBMIT</button>
      </motion.form>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 8%;
  display: flex;
  justify-content: center;
  gap: 2em;
  font-family: "Montserrat", cursive;
  background-image: var(--backgroundGradient2T);
  background-size: 50% 100%, 100% 100%, 57% 100%;
  background-repeat: no-repeat;
  .alert {
    position: fixed;
    top: 5em;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 0 12px 1px var(--shadowColor);
    padding: 14px 17px;
    border-radius: 20px 0 0 20px;
    transition: 0.4s;
    .icon {
      color: #119e11;
    }
  }
  .left {
    margin-top: 3%;
    h1 {
      font-size: 3.7em;
      line-height: 1.1em;
      font-weight: 400;
      span {
        color: var(--primaryColor);
      }
    }
    p {
      margin-right: 17%;
      color: var(--fontSecondaryColor);
      font-weight: 300;
      margin-top: 1em;
      max-width: 400px;
    }
    button {
      margin-top: 1em;
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 17px;
      font-size: 18px;
      font-family: "Comfortaa", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 680px;
    padding: 2em;
    h2 {
      font-weight: 300;
      text-align: center;
      margin-bottom: 10px;
    }
    input,
    textarea {
      font-family: "Montserrat", cursive;
      padding: 10px;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid var(--primaryColorDark);
      background: none;
      color: var(--fontPrimaryColor);
      outline: none;
      ::placeholder {
        color: var(--fontSecondaryColor);
      }
    }
    button {
      margin-top: 1em;
      display: flex;
      align-self: flex-start;
      margin: auto;
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 17px;
      font-size: 18px;
      font-family: "Comfortaa", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
  }
  @media (min-width: 1650px) {
    height: 65vh;
    background-size: 50% 100%, 90% 140%, 57% 100%;
  }
  @media (max-width: 1300px) {
    padding: 4em 7%;
    .left {
      h1 {
        font-size: 3em;
      }
    }
  }
  @media (max-width: 770px) {
    flex-direction: column;
    align-items: center;
    .left {
      width: 100%;
      h1 {
        font-size: 3em;
      }
    }
    form {
      width: 90%;
    }
  }
  @media (max-width: 550px) {
    padding: 4em 6%;
    form {
      width: 100%;
      padding: 10px;
    }
  }
  @media (max-width: 550px) {
    .left {
      h1 {
        font-size: 2.4em;
      }
    }
  }
  @media (max-width: 400px) {
    background-size: 70% 100%, 100% 100%, 127% 100%;
  }
`;

export default ContactUsComp;
