import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const Quote1 = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="right">
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 0.4, type: "spring" }}
        >
          YOUR SUCCESS,
        </motion.h1>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 1, type: "spring" }}
        >
          OUR MISSION!
        </motion.h1>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 8em 3%;
  display: flex;
  justify-content: center;
  gap: 1em;
  .right {
    h1 {
      font-size: 12vw;
      line-height: 1em;
      font-weight: 200;
      :nth-child(2) {
        color: var(--primaryColor);
      }
    }
  }
  @media (max-width: 500px) {
    padding: 4em 3%;
    .right {
      h1 {
        font-size: 17vw;
      }
    }
  }
`;

export default Quote1;
