import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion, useAnimation, useInView } from "framer-motion";

const ServicePreviews = () => {
  const [activeService, setActiveService] = useState("webServices");

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <h2 className="title">
        Pick a <span>Service</span>
      </h2>
      <div className="services">
        <motion.p
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 0.4, type: "spring" }}
          className={activeService === "webServices" ? "active" : ""}
          onClick={() => setActiveService("webServices")}
        >
          Web Services
        </motion.p>
        <motion.p
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 0.8, type: "spring" }}
          className={activeService === "seo" ? "active" : ""}
          onClick={() => setActiveService("seo")}
        >
          SEO
        </motion.p>
        <motion.p
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 1.2, type: "spring" }}
          className={activeService === "digitalMarketing" ? "active" : ""}
          onClick={() => setActiveService("digitalMarketing")}
        >
          Digital Marketing
        </motion.p>
        <motion.p
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 1.6, type: "spring" }}
          className={activeService === "contentCreation" ? "active" : ""}
          onClick={() => setActiveService("contentCreation")}
        >
          Content Creation
        </motion.p>
        <motion.p
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 2, type: "spring" }}
          className={activeService === "copywriting" ? "active" : ""}
          onClick={() => setActiveService("copywriting")}
        >
          Copywritng
        </motion.p>
        <motion.p
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 2.4, type: "spring" }}
          className={activeService === "brandingDesign" ? "active" : ""}
          onClick={() => setActiveService("brandingDesign")}
        >
          Branding Design
        </motion.p>
      </div>
      <div className="selectedService">
        <div className="left">
          {activeService === "webServices" && (
            <h1>Empower Your Brand Online</h1>
          )}
          {activeService === "seo" && <h1>Boost Your Visibility</h1>}
          {activeService === "digitalMarketing" && (
            <h1>Connect with Your Ideal Customers</h1>
          )}
          {activeService === "contentCreation" && (
            <h1>Engage and Grow Your Audience</h1>
          )}
          {activeService === "copywriting" && (
            <h1>Compelling Content That Converts</h1>
          )}
          {activeService === "brandingDesign" && (
            <h1>Stand Out and Be Memorable</h1>
          )}

          {activeService === "webServices" && (
            <p>
              Our web design and development services transform your online
              presence. Benefit from visually stunning websites that engage
              users and drive conversions, all while accurately reflecting your
              brand's identity.
            </p>
          )}
          {activeService === "seo" && (
            <p>
              With our SEO expertise, you'll enjoy improved visibility on search
              engines. Increase organic traffic, outrank competitors, and
              ultimately grow your business through higher search engine
              rankings.
            </p>
          )}
          {activeService === "digitalMarketing" && (
            <p>
              Our digital marketing strategies connect you with potential
              customers. Benefit from increased brand awareness, customer
              engagement, and lead generation through expertly crafted
              campaigns.
            </p>
          )}
          {activeService === "contentCreation" && (
            <p>
              Our content creation services captivate and convert. Benefit from
              engaging articles, blog posts, and videos that not only tell your
              story but also expand your online reach and audience.
            </p>
          )}
          {activeService === "copywriting" && (
            <p>
              Benefit from persuasive copy that drives action. Our words not
              only resonate with your audience but also transform visitors into
              loyal customers, enhancing your online conversion rates.
            </p>
          )}
          {activeService === "brandingDesign" && (
            <p>
              Our branding design services ensure your brand stands out. Benefit
              from captivating logos and visuals that define your unique
              identity, leaving a lasting impression in the digital landscape.
            </p>
          )}

          <div className="keywords">
            {activeService === "webServices" && (
              <>
                <h3>#webDesign</h3>
                <h3>#modern</h3>
                <h3>#professional</h3>
                <h3>#profitable</h3>
              </>
            )}
            {activeService === "seo" && (
              <>
                <h3>#seo</h3>
                <h3>#optimization</h3>
                <h3>#googleSearch</h3>
                <h3>#traffic</h3>
              </>
            )}
            {activeService === "digitalMarketing" && (
              <>
                <h3>#marketing</h3>
                <h3>#digital</h3>
                <h3>#ads</h3>
                <h3>#socialMediaAds</h3>
              </>
            )}
            {activeService === "contentCreation" && (
              <>
                <h3>#content</h3>
                <h3>#videoEditing</h3>
                <h3>#blogging</h3>
                <h3>#strategy</h3>
              </>
            )}
            {activeService === "copywriting" && (
              <>
                <h3>#salesCopy</h3>
                <h3>#seoCopywriting</h3>
                <h3>#blogging</h3>
                <h3>#adCopy</h3>
              </>
            )}
            {activeService === "brandingDesign" && (
              <>
                <h3>#branding</h3>
                <h3>#design</h3>
                <h3>#logoDesign</h3>
                <h3>#brandVibe</h3>
              </>
            )}
          </div>
          {activeService === "webServices" && (
            <Link to={"/services/web-services"}>
              <button>DISCOVER MORE</button>
            </Link>
          )}
          {activeService === "seo" && (
            <Link to={"/services/seo-services"}>
              <button>DISCOVER MORE</button>
            </Link>
          )}
          {activeService === "digitalMarketing" && (
            <Link to={"/services/digital-marketing"}>
              <button>DISCOVER MORE</button>
            </Link>
          )}
          {activeService === "contentCreation" && (
            <Link to={"/services/content-creation"}>
              <button>DISCOVER MORE</button>
            </Link>
          )}
          {activeService === "copywriting" && (
            <Link to={"/services/seo"}>
              <button>DISCOVER MORE</button>
            </Link>
          )}
          {activeService === "branding" && (
            <Link to={"/services/seo"}>
              <button>DISCOVER MORE</button>
            </Link>
          )}
        </div>
        {activeService === "webServices" && (
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/web-coding-9237378-7525168.png"
            alt=""
          />
        )}
        {activeService === "seo" && (
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/seo-analysis-5617621-4674332.png"
            alt=""
          />
        )}
        {activeService === "digitalMarketing" && (
          <img
            src="https://static.vecteezy.com/system/resources/previews/011/064/694/original/social-media-and-digital-marketing-3d-illustration-free-png.png"
            alt=""
          />
        )}
        {activeService === "contentCreation" && (
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/virtual-reality-development-process-9428484-7678624.png?f=webp"
            alt=""
          />
        )}
        {activeService === "copywriting" && (
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/copywriting-5291378-4431830.png?f=webp"
            alt=""
          />
        )}
        {activeService === "brandingDesign" && (
          <img
            src="https://static.vecteezy.com/system/resources/previews/021/014/363/original/logo-design-art-design-3d-illustration-png.png"
            alt=""
          />
        )}
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 4%;
  background: var(--backgroundGradient2T);
  background-size: 60% 90%, 100% 130%, 99% 120%;
  background-repeat: no-repeat;
  .title {
    text-align: center;
    font-weight: 400;
    color: var(--fontSecondaryColor);
    font-family: "Comfortaa", cursive;
    margin-bottom: 1em;
    span {
      color: var(--primaryColor);
    }
  }
  .services {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
    p {
      padding: 10px 17px;
      background: var(--secondaryBackgroundColor);
      border-radius: 20px;
      transition: 0.4s;
      cursor: pointer;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
    .active {
      color: var(--primaryColorDark);
    }
  }
  .selectedService {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 1em 7%;
    height: 70vh;
    img {
      width: 40%;
      max-width: 600px;
    }
    .left {
      h1 {
        font-weight: 400;
        color: var(--primaryColor);
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
        margin-top: 10px;
      }
      .keywords {
        display: flex;
        align-items: center;
        gap: 7px;
        flex-wrap: wrap;
        margin-top: 1em;
        h3 {
          font-weight: 400;
          padding: 4px 14px;
          background: var(--secondaryBackgroundColor);
          border-radius: 20px;
          transition: 0.4s;
          cursor: pointer;
        }
      }
      button {
        margin-top: 1em;
        background: var(--fontPrimaryColor);
        border: none;
        color: var(--primaryBackgroundColor);
        padding: 7px 14px;
        font-size: 19px;
        font-family: "Poppins", cursive;
        cursor: pointer;
        transition: 0.4s;
        :hover {
          box-shadow: 0 0 14px 2px var(--shadowColor);
        }
      }
    }
  }
  @media (min-width: 1660px) {
    .selectedService {
      height: 50vh;
      .left {
        h1 {
          font-size: 3em;
        }
        p {
          max-width: 700px;
        }
      }
    }
  }
  @media (max-width: 870px) {
    .selectedService {
      padding: 1em 4%;
      .left {
        width: 60%;
      }
    }
  }
  @media (max-width: 730px) {
    .selectedService {
      padding: 1em 2%;
      flex-direction: column;
      height: auto;
      .left {
        width: 99%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        h1 {
          line-height: 1.2em;
          margin-top: 10em;
        }
        .keywords {
          justify-content: center;
          gap: 4px 7px;
        }
      }
      img {
        width: 70%;
      }
    }
  }
`;

export default ServicePreviews;
