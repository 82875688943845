import React from "react";
import styled from "styled-components";

const SEOProcess = () => {
  return (
    <Container>
      <div className="title">
        <h2>5-step SEO Process</h2>
        <p>
          Here are 5 main steps our agency takes for SEO services. <br /> It
          depends on the type of website and SEO we need to optimize, but these
          are the main ones.
        </p>
      </div>
      <div className="items">
        <div className="item">
          <h1>01</h1>
          <h2>Research & Analysis</h2>
          <p>
            We kick off by conducting thorough keyword research and competitor
            analysis to shape our SEO strategy.
          </p>
        </div>
        <div className="item">
          <h1>02</h1>
          <h2>On-Page & Content</h2>
          <p>
            Next, we optimize web pages and curate compelling, keyword-rich
            content that resonates with your audience.
          </p>
        </div>
        <div className="item">
          <h1>03</h1>
          <h2>Technical & Off-Page SEO</h2>
          <p>
            We ensure your website's technical aspects are top-notch and engage
            in off-page efforts, including backlink building.
          </p>
        </div>
        <div className="item">
          <h1>04</h1>
          <h2>Monitoring & Improvement</h2>
          <p>
            We implement advanced analytics, keeping a close eye on performance
            and making ongoing adjustments for better results.
          </p>
        </div>
        <div className="item">
          <h1>05</h1>
          <h2>Client Reporting</h2>
          <p>
            Our commitment to transparency means you'll receive clear,
            actionable reports, and we maintain open communication throughout
            the process.
          </p>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3em 1em;
    margin-top: 3em;
    .item {
      position: relative;
      width: 30%;
      height: 170px;
      transition: 0.4s;
      :hover {
        h1 {
          transition: 0.4s;
          top: 4px;
          opacity: 1;
          color: var(--primaryColor);
        }
        h2 {
          margin-top: 3em;
        }
      }
      transition: 0.4s;
      h1 {
        font-size: 4em;
        position: absolute;
        opacity: 0.1;
        top: 8%;
      }
      h2 {
        transition: 0.4s;
        color: var(--primaryColor);
        font-weight: 500;
      }
      p {
        font-weight: 300;
        color: var(--fontSecondaryColor);
      }
    }
  }
  @media (max-width: 1225px) {
    .items {
      .item {
        height: auto;
      }
    }
  }
  @media (max-width: 1020px) {
    padding: 4em 2%;
    .items {
      .item {
      }
    }
  }
  @media (max-width: 780px) {
    .items {
      .item {
        width: 48%;
      }
    }
  }
  @media (max-width: 580px) {
    .items {
      gap: 1em;
      .item {
        width: 98%;
        height: auto;
      }
    }
  }
`;

export default SEOProcess;
