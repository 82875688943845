import React from "react";
import styled from "styled-components";

const WebsiteTypes = () => {
  return (
    <Container>
      <div className="item">
        <img
          src="https://static.vecteezy.com/system/resources/previews/010/873/246/original/3d-business-man-presenting-business-growth-illustration-png.png"
          alt=""
        />
        <span>
          <h2>Business Websites</h2>
          <p className="description">
            Your comprehensive online business headquarters. Our professional
            business websites serve as your digital storefront, allowing you to
            not only showcase your services but also engage with potential
            customers, build credibility, and establish a robust online
            presence.
          </p>
          <div className="keywords">
            <p>Online Presence</p>
            <hr />
            <p>Services</p>
            <hr />
            <p>Clients</p>
            <hr />
            <p>Marketing</p>
          </div>
        </span>
      </div>
      <div className="item reversed">
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/search-for-product-on-e-commerce-website-6209340-5102567.png"
          alt=""
        />
        <span>
          <h2>eCommerce Websites</h2>
          <p className="description">
            An all-in-one solution for online selling. Our e-commerce websites
            provide everything you need to launch a successful online store.
            From customizable product catalogs and secure payment processing to
            a user-friendly shopping experience, we'll help you turn your
            products into profits in the digital realm.
          </p>
          <div className="keywords">
            <p>Shopping</p>
            <hr />
            <p>Products</p>
            <hr />
            <p>Dropshipping</p>
            <hr />
            <p>Payments</p>
          </div>
        </span>
      </div>
      <div className="item">
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/portfolio-6762580-5588838.png"
          alt=""
        />
        <span>
          <h2>Portfolio Websites</h2>
          <p className="description">
            A visually stunning showcase of your talents and creations. Our
            portfolio websites offer a platform to elegantly display your
            creative work, whether you're an artist, photographer, designer, or
            writer. With a focus on aesthetics and ease of use, your portfolio
            becomes a compelling visual narrative of your expertise.
          </p>
          <div className="keywords">
            <p>Projects</p>
            <hr />
            <p>Skills</p>
            <hr />
            <p>Creativity</p>
            <hr />
            <p>Gallery</p>
          </div>
        </span>
      </div>
      <div className="item reversed">
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/blog-writing-6374004-5272619.png?f=webp"
          alt=""
        />
        <span>
          <h2>Blog or Content Websites</h2>
          <p className="description">
            Your content-sharing center. Our content websites make it a breeze
            to express your thoughts, stories, or knowledge. They're
            user-friendly, making publishing articles and updates simple and
            intuitive, allowing you to connect with your audience through the
            power of words and ideas.
          </p>
          <div className="keywords">
            <p>Blogging</p>
            <hr />
            <p>Articles</p>
            <hr />
            <p>Posts</p>
            <hr />
            <p>Ideas</p>
          </div>
        </span>
      </div>
      <div className="item">
        <img
          src="https://assets-global.website-files.com/6364b6fd26e298b11fb9391f/6364b6fd26e2983c58b93d94_3d-cv-education.png"
          alt=""
        />
        <span>
          <h2>Educational or E-Learning Websites</h2>
          <p className="description">
            Empowering knowledge-sharing and learning. Our educational websites
            are dedicated to providing a flexible and accessible online learning
            experience. Featuring an array of user-friendly tools, including
            courses, quizzes, video lectures, and progress tracking, we make
            teaching and learning a seamless and enriching journey.
          </p>
          <div className="keywords">
            <p>Courses</p>
            <hr />
            <p>Learning</p>
            <hr />
            <p>Resources</p>
            <hr />
            <p>Knowledge</p>
          </div>
        </span>
      </div>
      <div className="item reversed">
        <img
          src="https://static.vecteezy.com/system/resources/thumbnails/027/205/830/small_2x/booking-meeting-calendar-appointment-3d-illustration-people-using-appointment-business-application-planning-events-setting-reminders-time-online-scheduling-appointments-png.png"
          alt=""
        />
        <span>
          <h2>Booking and Reservation Websites</h2>
          <p className="description">
            Simplify booking and reservations with convenience. Our online
            platforms make it easy for clients to schedule appointments, book
            events, or make reservations. Offering a straightforward and
            efficient booking experience, we ensure that customers can easily
            secure their spots and manage their reservations hassle-free.
          </p>
          <div className="keywords">
            <p>Appointments</p>
            <hr />
            <p>Reservations</p>
            <hr />
            <p>Events</p>
            <hr />
            <p>Bookings</p>
          </div>
        </span>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 0;
  .item {
    width: 100%;
    padding: 1em 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    position: sticky;
    top: 3em;
    background: var(--trBackgroundColor);
    backdrop-filter: blur(20px);
    img {
      width: 500px;
    }
    span {
      h2 {
        color: var(--primaryColor);
        font-weight: 500;
      }
      .description {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
      .keywords {
        display: flex;
        justify-content: flex-start;
        gap: 1em;
        margin-top: 1em;
        p {
          font-size: 18px;
        }
      }
    }
  }
  .reversed {
    flex-direction: row-reverse;
    span {
      text-align: end;
      .keywords {
        justify-content: flex-end;
      }
    }
  }
  @media (max-width: 1010px) {
    .item {
      img {
        width: 400px;
      }
    }
  }
  @media (max-width: 1080px) {
    .item {
      span {
        .keywords {
          gap: 7px;
          flex-wrap: wrap;
          p {
            font-size: 17px;
          }
        }
      }
    }
  }
  @media (max-width: 910px) {
    .item {
      img {
        width: 330px;
      }
    }
  }
  @media (max-width: 670px) {
    .item {
      flex-direction: column;
      gap: 7px;
      top: 1em;
      span {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        .keywords {
          justify-content: center;
        }
      }
      img {
        width: 270px;
      }
    }
  }
`;

export default WebsiteTypes;
