import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const LogIn = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "ASites Agency - Log In Page";
  }, []);
  const [visibility, setVisibility] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const { currentUser } = useAuth();
  console.log(currentUser);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setMessage("");
      setError("");
      await login(emailRef.current.value, passwordRef.current.value);
      navigate("/");
      setMessage("You're signed up. Log in to your account now!");
    } catch {
      setError("Failed to log in");
    }
  }
  return (
    <Container>
      <motion.img
        src="https://cdn3d.iconscout.com/3d/premium/thumb/login-5707749-4781330.png"
        alt=""
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 70 }}
        transition={{
          duration: 0.7,
          bounce: 0.3,
          delay: 0.2,
          type: "spring",
        }}
      />
      <motion.form
        method="POST"
        onSubmit={handleSubmit}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{
          duration: 0.7,
          bounce: 0.3,
          delay: 0.8,
          type: "spring",
        }}
      >
        <h1>LOG IN</h1>
        <input
          ref={emailRef}
          type="email"
          placeholder="Enter Your Email Address..."
        />
        <div>
          <input
            ref={passwordRef}
            type={visibility === true ? "text" : "password"}
            placeholder="Enter Your Password"
          />
          {visibility === true ? (
            <FaLockOpen
              onClick={() => setVisibility(!visibility)}
              className="icon"
            />
          ) : (
            <FaLock
              onClick={() => setVisibility(!visibility)}
              className="icon"
            />
          )}
        </div>
        {error && <div className="failAlert alert">{error}</div>}
        {message && <div className="successAlert alert">{message}</div>}
        <div className="needAcc">
          <p>Don't have an Account?</p>
          <Link to={"/sign-up"}>
            <h3>Sign Up</h3>
          </Link>
        </div>
        <div className="signUpOptions">
          or continue with -
          <span>
            <FcGoogle className="googleIcon" />
          </span>
        </div>
        <button>Log In</button>
      </motion.form>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 7%;
  background: var(--backgroundGradient);
  color: var(--fontPrimaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  position: relative;
  img {
    width: 50%;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 500px;
    text-align: center;
    height: 480px;
    padding: 30px;
    h1 {
      font-weight: 500;
      color: var(--fontSecondaryColor);
      font-family: "Comfortaa", cursive;
    }
    input {
      background: var(--primaryBackgroundColor);
      margin-top: 12px;
      border-radius: 20px;
      border: 2px solid transparent;
      padding: 14px 20px;
      font-family: "Poppins", sans-serif;
      outline: none;
      font-size: 16px;
      width: 100%;
      background: transparent;
      box-shadow: 7px 7px 12px var(--shadowColor1),
        -4px -4px 12px var(--shadowColor2);
      color: var(--fontPrimaryColor);
      ::placeholder {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
    }
    .alert {
      text-align: center;
      color: white;
      background: #ff000086;
      display: flex;
      justify-content: center;
      width: auto;
      padding: 10px;
      border-radius: 10px;
      margin-top: 10px;
    }
    .successAlert {
      background: #0aaa0a92;
    }
    div {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .icon {
        font-size: 24px;
        right: 4%;
        top: 1.2em;
        position: absolute;
        cursor: pointer;
        color: var(--fontSecondaryColor);
      }
    }
    button {
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 7px 14px;
      font-size: 18px;
      margin-top: 1em;
      border-radius: 10px;
      font-family: "Poppins", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
    .needAcc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em 2%;
      p {
        color: var(--fontSecondaryColor);
      }
      h3 {
        color: var(--fontSecondaryColor);
        font-weight: 400;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .signUpOptions {
      display: flex;
      justify-content: center;
      gap: 1em;
      align-items: center;
      .googleIcon {
        font-size: 2em;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 970px) {
    padding: 4em 4%;
    img {
      width: 45%;
    }
  }
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    form {
      height: 340px;
    }
    img {
      width: 75%;
    }
  }
  @media (max-width: 500px) {
    padding: 7em 1%;
    img {
      width: 80%;
    }
    form {
      width: 100%;
      padding: 10px;
    }
  }
`;

export default LogIn;
