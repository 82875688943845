import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import dots from "../assets/homePageAssets/dots.png";
import DMarektingServices from "../components/servicesComponents/DMarektingServices";
import DMWhyChooseUs from "../components/servicesComponents/DMWhyChooseUs";
import DMFAQ from "../components/servicesComponents/DMFAQ";

const DigitalMarketingServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Digital Marketing Services - ASites Agency";
  }, []);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="banner">
        <div className="details">
          <motion.h1
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: 200 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 0.2,
              type: "spring",
            }}
          >
            Connecting You <br /> to{" "}
            <motion.span
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 0.7,
              }}
            >
              Your Audience
            </motion.span>
          </motion.h1>
          <motion.p
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 1,
            }}
          >
            Elevate your brand's online presence and reach your audience <br />{" "}
            with our expert digital marketing strategies.
          </motion.p>
          <div className="keywords">
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.3,
              }}
            >
              Digital Marekting
            </motion.p>
            <motion.hr
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 2.6,
              }}
            />
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.6,
              }}
            >
              DocialMedia
            </motion.p>
            <motion.hr
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 2.6,
              }}
            />
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.9,
              }}
            >
              Ads
            </motion.p>
            <motion.hr
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 2.6,
              }}
            />
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 2.2,
              }}
            >
              Branding
            </motion.p>
          </div>
          <img className="dots" src={dots} alt="" />
          <img className="dots2" src={dots} alt="" />
        </div>
        <motion.img
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: 200 }}
          transition={{
            duration: 0.7,
            delay: 0.5,
          }}
          src="https://static.vecteezy.com/system/resources/previews/011/019/173/original/social-media-and-digital-marketing-3d-illustration-png.png"
          alt="ASites Agency Digital Marketing Services Page Image"
        />
      </div>
      <div className="text">
        <h1>OUR PASSION,</h1>
        <h1>YOUR GROWTH!</h1>
      </div>
      <DMarektingServices />
      <DMWhyChooseUs />
      <DMFAQ />
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 0%;
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  .banner {
    padding: 1em 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--backgroundGradient2M);
    background-size: 30% 67%, 100% 100%, 97% 100%;
    background-repeat: no-repeat;
    .details {
      position: relative;
      h1 {
        font-size: 4em;
        font-weight: 500;
        line-height: 1.2em;
        span {
          color: var(--primaryColor);
        }
      }
      p {
        font-weight: 300;
        color: var(--fontSecondaryColor);
      }
      .keywords {
        display: flex;
        gap: 1em;
        margin-top: 1em;
        p {
          color: var(--fontPrimaryColor);
        }
      }
      .dots {
        position: absolute;
        opacity: 0.09;
        top: -10%;
      }
      .dots2 {
        position: absolute;
        opacity: 0.09;
        bottom: 20%;
        right: 0;
        width: 170px;
      }
    }
    img {
      object-fit: cover;
      width: 40%;
      max-width: 600px;
    }
  }
  .text {
    padding: 4em 5%;
    h1 {
      font-size: 7em;
      text-align: center;
      line-height: 1em;
      font-weight: 200;
      :nth-child(2) {
        font-weight: 400;
        color: var(--primaryColor);
      }
    }
  }
  @media (max-width: 1015px) {
    .banner {
      align-items: center;
      .details {
        margin-top: 0;
        h1 {
          font-size: 3.4em;
        }
      }
    }
  }
  @media (max-width: 945px) {
    .text {
      h1 {
        font-size: 6em;
      }
    }
  }
  @media (max-width: 865px) {
    .banner {
      .details {
        h1 {
          font-size: 3em;
        }
        .keywords {
          flex-wrap: wrap;
          gap: 10px;
        }
      }
    }
    .text {
      h1 {
        font-size: 5em;
      }
    }
  }
  @media (max-width: 765px) {
    .banner {
      flex-direction: column;
      .details {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .keywords {
          justify-content: center;
        }
      }
      .dots {
        left: -1em;
        width: 170px;
      }
      .dots2 {
        display: none;
      }
      img {
        width: 70%;
      }
    }
    .text {
      h1 {
        font-size: 4em;
      }
    }
  }
  @media (max-width: 550px) {
    .text {
      h1 {
        font-size: 3em;
      }
    }
    .banner {
      .details {
        .dots {
          display: none;
        }
      }
    }
  }
  @media (max-width: 450px) {
    .banner {
      .details {
        h1 {
          font-size: 2.2em;
        }
      }
      img {
        width: 90%;
      }
    }
    .text {
      h1 {
        font-size: 2.4em;
      }
    }
  }
  @media (max-width: 330px) {
    .banner {
      .details {
        h1 {
          font-size: 2em;
        }
      }
    }
  }
`;

export default DigitalMarketingServices;
