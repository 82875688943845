import React, { useEffect } from "react";
import styled from "styled-components";
import blackDots from "../assets/homePageAssets/dots.png";
import whitedots from "../assets/homePageAssets/whiteDots.png";
import TheServicesComponent from "../components/servicesComponents/TheServicesComponent";
import { motion } from "framer-motion";

const Services = ({ theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Services - ASites Agency";
  }, []);
  return (
    <Container>
      <div className="banner">
        {theme === "light" ? (
          <img src={blackDots} alt="" />
        ) : (
          <img className="white" src={whitedots} alt="" />
        )}
        <motion.h1
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: 170 }}
          transition={{
            duration: 0.7,
            bounce: 0.3,
            delay: 0.3,
            type: "spring",
          }}
        >
          Turning <span>Digital Dreams</span> <br />
          into <span>Real Success Stories</span>
        </motion.h1>
        <motion.p
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 0.9,
          }}
        >
          We don't provide just Services; we provide Services that come with
          perfect solutions for your problems for different categories.
        </motion.p>
        {theme === "light" ? (
          <img className="second" src={blackDots} alt="" />
        ) : (
          <img className="second white" src={whitedots} alt="" />
        )}
      </div>
      <TheServicesComponent />
    </Container>
  );
};
const Container = styled.div`
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  .banner {
    position: relative;
    padding: 7em 5%;
    background: var(--backgroundGradient2T);
    background-size: 40% 100%, 120% 100%, 49% 100%;
    background-repeat: no-repeat;

    img {
      position: absolute;
      width: 200px;
      left: 14%;
      opacity: 0.1;
    }
    .white {
      opacity: 0.4;
    }
    .second {
      position: absolute;
      left: 70%;
      bottom: 1em;
    }
    h1 {
      text-align: center;
      font-weight: 500;
      font-family: "Montserrat", cursive;
      font-size: 4em;
      margin-top: 1em;
      span {
        color: var(--primaryColor);
      }
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
      text-align: center;
    }
  }
  /* @media (min-width: 1930px) {
    .banner {
      h1 {
        font-size: 10.4em;
      }
    }
  } */
  @media (min-width: 1645px) {
    .banner {
      background-size: 40% 100%, 130% 100%, 49% 100%;
      padding-bottom: 10em;
      h1 {
        font-size: 7.4em;
      }
      p {
        font-size: 30px;
      }
    }
  }
  @media (max-width: 900px) {
    .banner {
      h1 {
        font-size: 3em;
      }
    }
  }
  @media (max-width: 680px) {
    .banner {
      img {
        width: 140px;
      }
      h1 {
        font-size: 2.5em;
      }
    }
  }
  @media (max-width: 555px) {
    .banner {
      h1 {
        font-size: 2em;
      }
    }
  }
`;

export default Services;
