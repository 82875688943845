import { Routes, Route, Navigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import Navbar from "./components/Navbar";
import Home from "./routes/Home";
import Footer from "./components/Footer";
import Services from "./routes/Services";
import SignUp from "./routes/SignUp";
import LogIn from "./routes/LogIn";
import PrivateRoutes from "./routes/PrivateRoutes";
import WebServices from "./routes/WebServices";
import SEOServices from "./routes/SEOServices";
import DigitalMarketingServices from "./routes/DigitalMarketingServices";
import ContentCreationServices from "./routes/ContentCreationServices";
import BrandingServices from "./routes/BrandingServices";
import Packages from "./routes/Packages";
import ContactUs from "./routes/ContactUs";
import { AuthProvider } from "./context/AuthContext";
import AccountPage from "./routes/AccountPage";
import SelectedPackage from "./routes/SelectedPackage";
import GetAProposal from "./routes/GetAProposal";
import ThankYouPage from "./routes/ThankYouPage";
import AboutUs from "./routes/AboutUs";
import NotFoundPage from "./routes/NotFoundPage";
import ReactGA from "react-ga";

function App() {
  const [theme, setTheme] = useLocalStorage("theme" ? "light" : "dark");

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  ReactGA.initialize("G-X2C4VHECKL");

  return (
    <>
      <AuthProvider>
        <div className="App" data-theme={theme}>
          <Navbar theme={theme} switchTheme={switchTheme} />
          <Routes>
            <Route
              path="/"
              element={<Home theme={theme} />}
              theme={theme}
              switchTheme={switchTheme}
            />
            <Route path="/services" element={<Services theme={theme} />} />
            <Route path="/packages" element={<Packages theme={theme} />} />
            {/* <Route path="/packages/:id" element={<SelectedPackage />} /> */}
            <Route path="/about-us" element={<AboutUs theme={theme} />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/log-in" element={<LogIn />} />
            <Route
              path="/account"
              element={
                <PrivateRoutes>
                  <AccountPage />
                </PrivateRoutes>
              }
            />
            <Route path="/get-a-proposal" element={<GetAProposal />} />
            <Route
              path="/thank-you-page"
              element={<ThankYouPage theme={theme} />}
            />

            {/* Services Pages */}
            <Route path="/services/web-services" element={<WebServices />} />
            <Route path="/services/seo-services" element={<SEOServices />} />
            <Route
              path="/services/digital-marketing"
              element={<DigitalMarketingServices />}
            />
            <Route
              path="/services/content-creation-services"
              element={<ContentCreationServices />}
            />
            <Route
              path="/services/branding-services"
              element={<BrandingServices />}
            />

            <Route path="*" element={<NotFoundPage />} />
            <Route path="/404" element={<Navigate to="/404" />} />
          </Routes>
          <Footer theme={theme} />
        </div>
      </AuthProvider>
    </>
  );
}

export default App;
