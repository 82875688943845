import React, { useEffect } from "react";
import styled from "styled-components";
import lmLogo from "../assets/ASitesAgency Logo L-D-NB.png";
import dmLogo from "../assets/ASitesAgency Logo L-NB.png";
import ASitesGoldLogo from "../assets/ASitesGoldLogo.png";
import ContactUsComp from "../components/homePageComponents/ContactUsComp";

const AboutUs = ({ theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "ASites Agency - About Us";
  }, []);
  return (
    <Container>
      <img className="logo" src={ASitesGoldLogo} alt="" />

      <div className="whoAreWe">
        <p>
          ASites Agency: The Architects of Your Success! <br /> <br /> At
          ASites, we specialize in empowering businesses to thrive in the
          digital realm. Our mission? To craft bespoke strategies tailored to
          your unique goals. We're the catalysts behind your digital success,
          offering a comprehensive suite of services—web design, SEO, content
          creation, branding, and more. <br /> <br /> Picture us as your digital
          partners, guiding you through the complexities of online growth with
          simplicity and expertise. Whether you're a startup or a seasoned
          enterprise, our user-friendly solutions and innovative approaches
          guarantee your digital presence stands out. <br /> <br /> Join us on a
          journey where your vision transforms into a captivating and successful
          reality.
        </p>
      </div>
      <div className="whyChooseUs">
        <h1>Why Choose Us</h1>
        <p>
          We listen to your requirements, absorbing every detail to sculpt
          strategies that truly resonate with your aspirations. At ASites
          Agency, our commitment isn’t just to deliver services; it’s to
          understand your vision and make it a digital reality. What sets us
          apart? Our meticulous approach: we decode your brand essence, unravel
          your challenges, and engineer solutions that fit seamlessly into your
          narrative. <br />
          <br />
          Transparency is our cornerstone. We pride ourselves on open
          communication, ensuring you're part of every decision and milestone.
          Our expertise spans a spectrum of digital facets—web design, SEO,
          content creation, and branding—each meticulously executed with a focus
          on quality, innovation, and your bottom line. <br />
          <br />
          With us, it's not just about delivering projects; it's about building
          lasting partnerships. Your success fuels our drive, and we're here to
          foster an online presence that resonates, captivates, and excels in
          the digital sphere. Trust us to transform your aspirations into a
          tangible, thriving online identity.
        </p>
      </div>
      <ContactUsComp />
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 0%;
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  .logo {
    width: 40%;
    padding-top: 5%;
    display: flex;
    margin: auto;
  }
  .title {
    text-align: center;
    font-size: 3em;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    padding: 2em 3% 1em 3%;
  }
  .whoAreWe {
    padding: 0 10%;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    p {
      max-width: 1400px;
      font-weight: 300;
    }
  }
  .whyChooseUs {
    padding: 4em 8%;
    background: var(--backgroundGradient2T);
    background-size: 40% 100%, 80% 120%, 49% 100%;
    background-repeat: no-repeat;
    h1 {
      text-align: center;
      font-size: 3em;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
      padding: 2em 3% 1em 3%;
    }
    p {
      text-align: center;
      font-size: 20px;
      font-weight: 300;
    }
  }
  @media (min-width: 1500px) {
    .whoAreWe {
      img {
        max-width: 700px;
      }
      p {
        font-size: 22px;
        font-weight: 300;
      }
    }
    .whyChooseUs {
      p {
        font-size: 22px;
      }
    }
  }
  @media (max-width: 1000px) {
    .whoAreWe {
      flex-direction: column;
      text-align: center;
      img {
        width: 300px;
      }
    }
  }
  @media (max-width: 620px) {
    .whoAreWe {
      background-size: 40% 100%, 100% 120%, 49% 100%;
      img {
        width: 90%;
      }
    }
    .whyChooseUs {
      background-size: 100% 130%, 100% 120%, 79% 100%;
      h1 {
        font-size: 2.3em;
      }
    }
  }
`;

export default AboutUs;
