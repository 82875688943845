import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import dots from "../assets/homePageAssets/dots.png";
import BrandingServicesComp from "../components/servicesComponents/BrandingServicesComp";
import BrandingWhyChooseUs from "../components/servicesComponents/BrandingWhyChooseUs";
import BrandingFAQ from "../components/servicesComponents/BrandingFAQ";

const BrandingServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Branding Services - ASites Agency";
  }, []);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const animation1 = useAnimation();
  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="banner">
        <div className="details">
          <img src={dots} className="dots" alt="" />
          <motion.h1
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: 200 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 0.2,
              type: "spring",
            }}
          >
            Elevate Your <span>Brand</span>, <br /> Elevate Your{" "}
            <span>Business</span>
          </motion.h1>
          <motion.p
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 1,
            }}
          >
            Branding That Evokes Emotions and Trust.
          </motion.p>
          <div className="tags">
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.3,
              }}
            >
              Identity
            </motion.p>
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.6,
              }}
            >
              Logo
            </motion.p>
            <motion.p
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.9,
              }}
            >
              Guidelines
            </motion.p>
          </div>
        </div>
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/brand-marketing-5571484-4659647.png"
          alt="ASites Agency Branding Services Page Image"
        />
      </div>
      <div className="brandingImp">
        <div className="title">
          <h2>Why Branding Matters</h2>
          <p>
            There are quite a lot of reasons why Branding matter, <br />
            but here are 3 main ones.
          </p>
        </div>
        <div className="reasons">
          <motion.div
            ref={ref}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 0.4,
            }}
          >
            <hr />
            <h2>Customer Trust and Loyalty</h2>
            <p>
              Strong branding builds trust and loyalty, fostering lasting
              customer relationships.
            </p>
          </motion.div>
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 0.4,
            }}
          >
            <hr />
            <h2>Competitive Edge</h2>
            <p>
              Effective branding sets your business apart, attracting attention
              and creating a lasting impression.
            </p>
          </motion.div>
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 0.4,
            }}
          >
            <hr />
            <h2>Perceived Value</h2>
            <p>
              A strong brand often commands a higher value, simplifying growth
              and expansion.
            </p>
          </motion.div>
        </div>
      </div>
      <BrandingServicesComp />
      <BrandingWhyChooseUs />
      <BrandingFAQ />
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 0%;
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  .banner {
    background: var(--backgroundGradient2M);
    background-size: 30% 67%, 100% 100%, 97% 100%;
    background-repeat: no-repeat;
    padding: 0em 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    .details {
      position: relative;
      img {
        position: absolute;
        width: 170px;
        opacity: 0.08;
        top: -2em;
      }
      h1 {
        font-size: 3.5em;
        line-height: 1.2em;
        font-weight: 500;
        span {
          color: var(--primaryColor);
        }
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
      .tags {
        display: flex;
        gap: 1em;
        margin-top: 10px;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 80px;
          border: 2px solid var(--primaryColor);
          border-radius: 50%;
          font-weight: 500;
          color: var(--fontPrimaryColor);
        }
      }
    }
    img {
      width: 37%;
    }
  }
  .brandingImp {
    padding: 4em 4%;
    .title {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      h2 {
        color: var(--primaryColorDark);
        font-weight: 400;
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
    }
    .reasons {
      display: flex;
      justify-content: center;
      gap: 1em;
      margin-top: 2em;
      div {
        width: 30%;
        h2 {
          color: var(--primaryColor);
          font-weight: 400;
        }
        p {
          color: var(--fontSecondaryColor);
          font-weight: 300;
        }
      }
    }
  }
  @media (max-width: 1050px) {
    .banner {
      .details {
        h1 {
          font-size: 3em;
        }
      }
    }
  }
  @media (max-width: 900px) {
    .banner {
      .details {
        h1 {
          font-size: 2.7em;
        }
      }
    }
  }
  @media (max-width: 810px) {
    .banner {
      flex-direction: column;
      .details {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 1em;
        h1 {
          font-size: 2.7em;
        }
      }
      img {
        width: 70%;
      }
    }
    .brandingImp {
      flex-direction: column;
      div {
        width: 100%;
      }
    }
  }
  @media (max-width: 400px) {
    .banner {
      .details {
        h1 {
          font-size: 2.3em;
        }
        .tags {
          gap: 7px;
        }
      }
    }
  }
`;

export default BrandingServices;
