import React from "react";
import styled from "styled-components";
import fullLogoL from "../assets/ASitesAgency Logo L-NB.png";
import fullLogoD from "../assets/ASitesAgency Logo L-D-NB.png";
import { Link } from "react-router-dom";
import { AiFillInstagram } from "react-icons/ai";

const Footer = ({ theme }) => {
  const openCalendlyPopup = () => {
    window.open(
      "https://calendly.com/asitesagency/free-consultation",
      "calendlyPopup",
      "width=800,height=600"
    );
  };
  return (
    <Container>
      <div className="col1">
        {theme === "dark" ? (
          <img src={fullLogoD} alt="" />
        ) : (
          <img src={fullLogoL} alt="" />
        )}
        <h2>Design Your Success</h2>
        <Link to={"/get-a-proposal"}>
          <button>GET A PROPOSAL</button>
        </Link>
      </div>
      <div className="columns">
        <div className="col">
          <h2>Quick Links</h2>
          <Link to={"/"} className="link-styles">
            <p>Home</p>
          </Link>
          <Link to={"/services"} className="link-styles">
            <p>Services</p>
          </Link>
          <Link to={"/packages"} className="link-styles">
            <p>Packages</p>
          </Link>
          <Link to={"/about-us"} className="link-styles">
            <p>About Us</p>
          </Link>
          <Link to={"/contact-us"} className="link-styles">
            <p>Contact Us</p>
          </Link>
        </div>
        <div className="col">
          <h2>Services</h2>
          <Link to={"/services/web-services"} className="link-styles">
            <p>Web Services</p>
          </Link>
          <Link to={"/services/seo-services"} className="link-styles">
            <p>SEO</p>
          </Link>
          <Link to={"/services/digital-marketing"} className="link-styles">
            <p>Digital Marketing</p>
          </Link>
          <Link
            to={"/services/content-creation-services"}
            className="link-styles"
          >
            <p>Content Creation</p>
          </Link>
          <Link to={"/services/branding-services"} className="link-styles">
            <p>Branding</p>
          </Link>
        </div>
        <div className="col">
          <h2>Contact</h2>
          <p>asitesagency@gmail.com</p>
          <p onClick={openCalendlyPopup}>Book a Call</p>
          <Link
            to={"https://www.instagram.com/asitesagency/"}
            target="_blank"
            className="link-styles"
          >
            <AiFillInstagram className="icon" />
          </Link>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 2em 3%;
  background: var(--backgroundGradient3);
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2em;
  box-shadow: 0 0 14px 2px var(--shadowColor);
  .col1 {
    img {
      width: 200px;
    }
    h2 {
      color: var(--fontSecondaryColor);
      font-weight: 400;
    }
    button {
      margin-top: 1em;
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 17px;
      font-size: 18px;
      font-family: "Comfortaa", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
  }
  .columns {
    display: flex;
    gap: 4em;
    h2 {
      color: var(--fontPrimaryColor);
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 18px;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
      cursor: pointer;
      :hover {
        color: var(--fontPrimaryColor);
      }
    }
    .icon {
      font-size: 3em;
      color: var(--primaryColor);
    }
  }
  @media (max-width: 920px) {
    .columns {
      gap: 2em;
    }
  }
  @media (max-width: 860px) {
    flex-direction: column;
    .columns {
      gap: 2em;
    }
  }
  @media (max-width: 570px) {
    .columns {
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      gap: 2em;
    }
  }
`;

export default Footer;
