import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import WCUimg from "../../assets/homePageAssets/WCUimg.png";

const WhyChooseUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div>
        <motion.h1
          ref={ref}
          variants={{
            hidden: { opacity: 0, x: -100 },
            visible: { opacity: 1, x: 0 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 0.4, type: "spring" }}
        >
          Why hire us?
        </motion.h1>
        <motion.p
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 1, delay: 0.8, type: "spring" }}
        >
          ASites Agency: Your trusted partner for online success. Why choose us?
          Your success is our success. If you're not satisfied with the results
          we provide, we don't take your money or give it back.
        </motion.p>
        <span>
          <motion.div
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{ duration: 1, delay: 1, type: "spring" }}
          >
            <h1>40+</h1>
            <p>Satisfied Clients</p>
          </motion.div>
          <motion.hr
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{ duration: 1, delay: 1.2, type: "spring" }}
          />
          <motion.div
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{ duration: 1, delay: 1.4, type: "spring" }}
          >
            <h1>60+</h1>
            <p>Projects</p>
          </motion.div>
          <motion.hr
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{ duration: 1, delay: 1.6, type: "spring" }}
          />
          <motion.div
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{ duration: 1, delay: 1.8, type: "spring" }}
          >
            <h1>7+</h1>
            <p>Years of experience</p>
          </motion.div>
        </span>
        <Link to={"/get-a-proposal"} className="link-styles">
          <motion.button
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 },
            }}
            initial="hidden"
            animate={animation1}
            transition={{ duration: 1, delay: 2.1, type: "spring" }}
          >
            GET A PROPOSAL
          </motion.button>
        </Link>
      </div>
      <img src={WCUimg} alt="ASites Agency - Why Hire Us" />
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 4% 7em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--backgroundGradient2M);
  background-size: 50% 100%, 100% 130%, 99% 120%;
  background-repeat: no-repeat;
  img {
    width: 50%;
    max-width: 900px;
  }
  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;
    h1 {
      color: var(--primaryColor);
      font-weight: 400;
      font-family: "Comfortaa";
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
    span {
      display: flex;
      gap: 1em;
      width: 100%;
      justify-content: center;
      hr {
        border: 1px solid var(--primaryColor);
      }
      div {
        width: 170px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        text-align: center;
      }
    }
    button {
      margin-top: 1em;
      display: flex;
      align-self: flex-start;
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 17px;
      font-size: 18px;
      font-family: "Comfortaa", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
  }
  @media (min-width: 2240px) {
    background-size: 44% 90%, 84% 130%, 99% 110%;
  }
  @media (min-width: 1700px) {
    div {
      gap: 1em;
      h1 {
        font-size: 3em;
      }
      p {
        max-width: 700px;
        font-size: 20px;
      }
      span {
        div {
          width: 200px;
          h1 {
            font-size: 3.3em;
          }
        }
      }
      button {
        font-size: 21px;
      }
    }
  }
  @media (max-width: 1270px) {
    padding: 7em 4% 7em 7%;
    div {
      span {
        div {
          width: 140px;
        }
      }
    }
  }
  @media (max-width: 970px) {
    padding: 7em 4% 7em 4%;
    div {
      span {
        div {
          width: auto;
        }
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
    div {
      text-align: center;
      button {
        align-self: center;
        margin: auto;
      }
    }
    img {
      width: 90%;
    }
  }
  @media (max-width: 400px) {
    img {
      width: 100%;
    }
  }
`;

export default WhyChooseUs;
