import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const CWServicesComp = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="title">
        <h2>Our Content Creation Services</h2>
        <p>
          Here are our main Copywritng Services we offer. <br />
          Poerty words that convert into clients.
        </p>
      </div>
      <div className="services">
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4 }}
          className="service"
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/ad-4933651-4112603.png"
            alt=""
          />
          <h2>Ad Copywriting</h2>
          <p>
            Compelling ad copy prompts action, increasing conversions, sales,
            and revenue growth for your business.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 0.3 }}
          className="service"
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/social-media-marketing-5463988-4568176.png"
            alt=""
          />
          <h2>Social Media Copy</h2>
          <p>
            Our social media copy sparks conversations, increases followers, and
            amplifies your online presence, contributing to community and brand
            growth.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 0.6 }}
          className="service"
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/keep-notes-4332354-3612277.png"
            alt=""
          />
          <h2>Website Copy</h2>
          <p>
            Website copy communicates your brand's voice, engaging visitors, and
            boosting conversions, ensuring sustainable growth
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 0.9 }}
          className="service"
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/seo-tag-8271106-6649168.png?f=webp"
            alt=""
          />
          <h2>SEO-Optimized Copy</h2>
          <p>
            Our SEO-optimized copy boosts online visibility and rankings,
            driving organic traffic and expanding your digital presence
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 1.2 }}
          className="service"
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/send-email-4609615-3811465.png"
            alt=""
          />
          <h2>Email Marketing</h2>
          <p>
            Our email copywriting engages subscribers, nurturing leads into
            loyal customers and increasing sales for your business.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 1.5 }}
          className="service"
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/copy-2872259-2389470.png"
            alt=""
          />
          <h2>Sales Letters</h2>
          <p>
            Persuasive sales letters turn prospects into loyal customers,
            increasing revenue and expanding your customer base.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 1.8 }}
          className="service"
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/product-5806313-4863042.png"
            alt=""
          />
          <h2>Product Descriptions</h2>
          <p>
            Vivid product descriptions entice shoppers, increasing sales and
            revenue, and enhancing your e-commerce business.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 2.1 }}
          className="service"
        >
          <img
            src="https://static.vecteezy.com/system/resources/previews/022/453/602/original/3d-script-for-podcast-free-png.png"
            alt=""
          />
          <h2>Podcast Copy</h2>
          <p>
            Podcast scripts offer an authentic way to connect with your
            audience, building community and fostering loyalty.
          </p>
        </motion.div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
  .services {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2em;
    .service {
      width: 330px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 130px;
      }
      h2 {
        font-weight: 500;
        color: var(--primaryColor);
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
    }
  }
  @media (max-width: 1150px) {
    .services {
      .service {
        width: 280px;
      }
    }
  }
  @media (max-width: 985px) {
    .services {
      .service {
        width: 47%;
      }
    }
  }
  @media (max-width: 580px) {
    .services {
      .service {
        width: 97%;
      }
    }
  }
`;

export default CWServicesComp;
