import React from "react";
import styled from "styled-components";
import blackDots from "../../assets/homePageAssets/dots.png";
import whiteDots from "../../assets/homePageAssets/whiteDots.png";
import { motion } from "framer-motion";

const PackagesBanner = ({ theme }) => {
  return (
    <Container>
      {theme === "light" ? (
        <img src={blackDots} className="dots1" alt="" />
      ) : (
        <img className="dots1 whiteDots" src={whiteDots} alt="" />
      )}
      <motion.h1
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 70 }}
        transition={{
          duration: 0.7,
          bounce: 0.3,
          delay: 0.3,
          type: "spring",
        }}
      >
        Custom <span>Solutions</span> for Unique Goals.
      </motion.h1>
      <motion.h1
        animate={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 70 }}
        transition={{
          duration: 0.7,
          bounce: 0.3,
          delay: 0.3,
          type: "spring",
        }}
      >
        Tailored for <span>Excellence.</span>
      </motion.h1>
      <motion.p
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{
          duration: 0.7,
          delay: 0.7,
        }}
      >
        Our packages are not just services; they're personalized journeys
        towards achieving your dreams.
      </motion.p>
      <div className="tags">
        <motion.h3
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 0.9,
          }}
        >
          Comprehensive
        </motion.h3>
        <motion.hr
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 1.5,
          }}
        />
        <motion.h3
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 1.1,
          }}
        >
          Personalized
        </motion.h3>
        <motion.hr
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 1.5,
          }}
        />
        <motion.h3
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{
            duration: 0.7,
            delay: 1.3,
          }}
        >
          Solutions
        </motion.h3>
      </div>
      {theme === "light" ? (
        <img src={blackDots} className="dots2" alt="" />
      ) : (
        <img className="dots2 whiteDots" src={whiteDots} alt="" />
      )}
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 4%;
  text-align: center;
  position: relative;
  background: var(--backgroundGradient2T);
  background-size: 50% 90%, 120% 100%, 100% 100%;
  background-repeat: no-repeat;
  .dots1 {
    width: 170px;
    position: absolute;
    opacity: 0.08;
    left: 10%;
  }
  .dots2 {
    width: 170px;
    position: absolute;
    opacity: 0.08;
    right: 10%;
    bottom: 4em;
  }
  .whiteDots {
    opacity: 0.8;
  }
  h1 {
    margin-top: 1em;
    font-size: 4em;
    font-weight: 400;
    line-height: 1.1em;
    font-family: "Montserrat", cursive;
    span {
      color: var(--primaryColor);
    }
    :nth-child(3) {
      margin-top: 0;
    }
  }
  p {
    color: var(--fontSecondaryColor);
    font-weight: 300;
  }
  .tags {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 2em;
    h3 {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  @media (min-width: 1780px) {
    background-size: 40% 100%, 130% 100%, 49% 100%;
    padding-bottom: 10em;
    h1 {
      font-size: 7em;
    }
    p {
      font-size: 30px;
    }
  }
  @media (max-width: 1230px) {
    h1 {
      font-size: 3.4em;
    }
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 2.4em;
    }
    .tags {
      flex-wrap: wrap;
    }
  }
`;

export default PackagesBanner;
