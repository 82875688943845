import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import { FaBookmark } from "react-icons/fa";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { FaCheckCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Package = ({ item }) => {
  const { currentUser } = useAuth();
  const [likedItems, setLikedItems] = useState([]);

  useEffect(() => {
    const storedLikedItems = localStorage.getItem("likedItems");
    if (storedLikedItems) {
      setLikedItems(JSON.parse(storedLikedItems));
    }
  }, []);

  const packageId = doc(db, "users", `${currentUser?.email}`);

  const isLiked = likedItems.includes(item.id);

  const savePackage = async () => {
    if (currentUser?.email) {
      await updateDoc(packageId, {
        savedPackages: arrayUnion({
          id: item.id,
          name: item.name,
          imageURL: item.imageURL,
        }),
      });
    } else {
      alert("Please log in to save a package");
    }

    if (currentUser?.email) {
      const updatedLikedItems = isLiked
        ? likedItems.filter((likedId) => likedId !== item.id)
        : [...likedItems, item.id];

      setLikedItems(updatedLikedItems);
      localStorage.setItem("likedItems", JSON.stringify(updatedLikedItems));
    } else {
      alert("Please log in to save a package");
    }
  };

  const [alert, setAlert] = useState(false);
  const handleAlert = () => {
    setAlert(true);

    setTimeout(() => {
      setAlert(false);
    }, 2000);
  };

  return (
    <Container>
      {/* {alert === true ? (
        <div className="alert">
          <FaCheckCircle className="icon" />
          <p>Package Saved!</p>
        </div>
      ) : (
        ""
      )} */}
      <Link to={`${item.id}`} className="link-styles">
        <img src={item.imageURL} alt="" />
      </Link>
      <span>
        <Link to={`${item.id}`} className="link-styles">
          <h3>{item.name}</h3>
        </Link>
        <div>
          <FaBookmark onClick={savePackage} className="icon" />
        </div>
      </span>
    </Container>
  );
};
const Container = styled.div`
  width: 240px;
  .alert {
    position: fixed;
    top: 5em;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 0 12px 1px var(--shadowColor);
    padding: 14px 17px;
    border-radius: 20px 0 0 20px;
    transition: 0.4s;
    background: var(--primaryBackgroundColor);
    .icon {
      color: #119e11;
    }
  }
  img {
    cursor: pointer;
    width: 100%;
    box-shadow: 12px 20px 19px var(--shadowColor1),
      -12px -12px 19px var(--shadowColor2);
    transition: 0.4s;
    :hover {
      box-shadow: 0 0 13px 4px var(--shadowColor);
    }
  }
  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    margin-top: 1em;
    h3 {
      cursor: pointer;
      color: var(--fontSecondaryColor);
      font-weight: 400;
      line-height: 1.1em;
    }
    .icon {
      width: 40px;
      font-size: 2em;
      transition: 0.3s;
      cursor: pointer;
      :hover {
        transform: scale(1.07);
      }
    }
  }
  h2 {
    font-weight: 400;
    line-height: 1.1em;
    color: var(--fontSecondaryColor);
    margin-top: 20%;
    margin-bottom: 10px;
    text-align: center;
  }
  button {
    display: flex;
    align-self: flex-start;
    margin: auto;
    background: var(--fontPrimaryColor);
    border: none;
    color: var(--primaryBackgroundColor);
    padding: 17px;
    font-size: 18px;
    font-family: "Comfortaa", cursive;
    cursor: pointer;
    line-height: 1.3em;
    transition: 0.4s;
    :hover {
      box-shadow: 0 0 14px 2px var(--shadowColor);
    }
  }
  @media (max-width: 630px) {
    width: 90%;
  }
`;

export default Package;
