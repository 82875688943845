import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../context/AuthContext";
import { db } from "../../firebase";
import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";

const SavedPackages = () => {
  const [packages, setPackages] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "users", `${currentUser?.email}`),
      (doc) => {
        setPackages(doc.data()?.savedPackages);
      }
    );
    return () => unsubscribe();
  }, [currentUser?.email]);

  useEffect(() => {
    onSnapshot(doc(db, "users", `${currentUser?.email}`), (doc) => {
      setPackages(doc.data()?.savedPackages);
    });
  }, [currentUser?.email]);

  const packageRef = doc(db, "users", `${currentUser?.email}`);

  const deletePackage = async (passedID) => {
    try {
      const result = packages.filter((item) => item.id !== passedID);
      await updateDoc(packageRef, {
        savedPackages: result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <div className="title">
        <h2>Your Saved Packages</h2>
        <p>
          To save a package, go to{" "}
          <Link className="link" to={"/packages"}>
            Packages Page
          </Link>
        </p>
      </div>
      <div className="saved">
        {packages.length === 0 ? (
          <p>No saved packages yet!</p>
        ) : (
          packages.map((item) => (
            <div key={item.id}>
              <Link to={`/packages/${item.id}`}>
                <img src={item.imageURL} alt="" />
              </Link>
              <span>
                <h2>{item.name}</h2>
                <button onClick={() => deletePackage(item.id)}>Unsave</button>
              </span>
            </div>
          ))
        )}
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  font-family: "Montserrat", sans-serif;
  .title {
    margin-bottom: 2em;
    text-align: center;
    h2 {
      font-weight: 500;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
    .link {
      color: var(--primaryColor);
    }
  }
  .saved {
    display: flex;
    justify-content: center;
    gap: 2em;
    flex-wrap: wrap;
    div {
      width: 290px;
      transition: 0.3s;
      img {
        width: 100%;
      }
      span {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 7px;
        h2 {
          line-height: 1.1em;
          font-size: 20px;
          font-weight: 400;
        }
        button {
          font-size: 18px;
          padding: 10px 14px;
          background: #e48888;
          border: none;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }
  }
`;

export default SavedPackages;
