import React from "react";
import styled from "styled-components";

const DMarektingServices = () => {
  return (
    <Container>
      <div className="item">
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/social-media-marketing-7194266-5862479.png"
          alt=""
        />
        <div>
          <h2>Social Media Marketing</h2>
          <p>
            Fuel audience engagement through our dynamic social media campaigns.
            We'll amplify brand recognition, foster interactions, and ignite
            sustained growth in your online community.
          </p>
          <div className="tags">
            <h3>#adDesign&copy</h3>
            <hr />
            <h3>#contentCreation</h3>
            <hr />
            <h3>#emailMarketing</h3>
          </div>
        </div>
      </div>
      <div className="item reversed">
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/digital-content-creator-recording-video-5984564-4971234.png?f=webp"
          alt=""
        />
        <div>
          <h2>Content Creation and Marketing</h2>
          <p>
            Transform your digital content with our expert creation and
            marketing services. We craft compelling, informative, and inspiring
            materials that resonate deeply with your target audience.
          </p>
          <div className="tags">
            <h3>#contentCreation</h3>
            <hr />
            <h3>#copywriting</h3>
            <hr />
            <h3>#marketing</h3>
          </div>
        </div>
      </div>
      <div className="item">
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/pay-per-click-5402861-4521474.png"
          alt=""
        />
        <div>
          <h2>Pay-Per-Click Advertising (PPC)</h2>
          <p>
            Maximize your advertising budget with our tailored PPC campaigns.
            Witness soaring conversion rates, impressive ROI, and precise
            targeting in the competitive world of online advertising.
          </p>
          <div className="tags">
            <h3>#ppc</h3>
            <hr />
            <h3>#advertising</h3>
            <hr />
            <h3>#rio</h3>
          </div>
        </div>
      </div>
      <div className="item reversed">
        <img
          src="https://static.vecteezy.com/system/resources/previews/012/097/891/original/3d-illustration-email-marketing-png.png"
          alt=""
        />
        <div>
          <h2>Email Marketing</h2>
          <p>
            Leverage the influential channel of email marketing to nurture
            client relationships, drive engagement, and convert leads into loyal
            customers, all while achieving substantial growth.
          </p>
          <div className="tags">
            <h3>#emailMarketing</h3>
            <hr />
            <h3>#outreach</h3>
            <hr />
            <h3>#leads</h3>
          </div>
        </div>
      </div>
      <div className="item">
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/affiliate-marketing-4621008-3839967.png"
          alt=""
        />
        <div>
          <h2>Affiliate Marketing</h2>
          <p>
            Unlock the potential of affiliate marketing with our expert
            strategies. We'll supercharge your online presence, boost
            conversions, and expand your revenue in the digital realm.
          </p>
          <div className="tags">
            <h3>#affiliateMarketing</h3>
            <hr />
            <h3>#referals</h3>
            <hr />
            <h3>#commissions</h3>
          </div>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3em;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: 90%;
    padding: 20px 2em;
    border-radius: 20px;
    box-shadow: 0 0 12px 2px var(--shadowColor);
    background: var(--trBackgroundColor);
    backdrop-filter: blur(20px);
    top: 5em;
    position: sticky;
    max-width: 1400px;
    div {
      h2 {
        color: var(--primaryColor);
        font-weight: 500;
        font-size: 2em;
        line-height: 1.2em;
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
      .tags {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        margin-top: 1em;
        h3 {
          font-weight: 300;
          font-size: 18px;
        }
      }
    }
    img {
      width: 40%;
    }
    :nth-child(2) {
      top: 7em;
    }
    :nth-child(3) {
      top: 9em;
    }
    :nth-child(4) {
      top: 11em;
    }
  }
  .reversed {
    flex-direction: row-reverse;
    div {
      text-align: end;
      display: flex;
      flex-direction: column;
      align-items: end;
      .tags {
        flex-direction: row;
      }
    }
  }
  @media (max-width: 680px) {
    .item {
      flex-direction: column;
      text-align: center;
      padding: 7px 2em 2em 2em;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
          font-size: 1em;
        }
        .tags {
          flex-direction: row;
          justify-content: center;
        }
      }
    }
  }
  @media (max-width: 420px) {
    .item {
      padding: 7px 10px 10px 10px;
    }
  }
`;

export default DMarektingServices;
