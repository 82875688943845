import React from "react";
import styled from "styled-components";
import data from "../data/packages";
import Package from "../packagesComponents/Package";

const PackagesComp = () => {
  return (
    <Container>
      <div className="title">
        <h2>Choose Your Fuel for Success</h2>
        <p>
          Discover packages designed to meet your unique needs, <br /> from
          comprehensive solutions to targeted services.
        </p>
      </div>
      <div className="packages">
        {data.map((item) => (
          <Package item={item} />
          // <div className="package">
          //   <Link to={`${item.id}`} className="link-styles">
          //     <img src={item.imageURL} alt="" />
          //   </Link>
          //   <span>
          //     <Link to={`${item.id}`} className="link-styles">
          //       <h3>{item.name}</h3>
          //     </Link>
          //     <CiBookmark onClick={savePackage} className="icon" />
          //   </span>
          // </div>
        ))}
        {/* <div className="package">
          <h2>Can't find the right one for you?</h2>
          <button>
            CREATE A <br /> CUSTOM PACKAGE
          </button>
        </div> */}
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 8%;
  background-image: var(--backgroundGradient2T);
  background-size: 70% 100%, 130% 100%, 100% 100%;
  background-repeat: no-repeat;
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
  .packages {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3em;
    .package {
      width: 240px;
      img {
        cursor: pointer;
        width: 100%;
        box-shadow: 12px 20px 19px var(--shadowColor1),
          -12px -12px 19px var(--shadowColor2);
        transition: 0.4s;
        :hover {
          box-shadow: 0 0 13px 4px var(--shadowColor);
        }
      }
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 7px;
        margin-top: 1em;
        h3 {
          cursor: pointer;
          color: var(--fontSecondaryColor);
          font-weight: 400;
          line-height: 1.1em;
        }
        .icon {
          width: 40px;
          font-size: 2em;
        }
      }
      h2 {
        font-weight: 400;
        line-height: 1.1em;
        color: var(--fontSecondaryColor);
        margin-top: 20%;
        margin-bottom: 10px;
        text-align: center;
      }
      button {
        display: flex;
        align-self: flex-start;
        margin: auto;
        background: var(--fontPrimaryColor);
        border: none;
        color: var(--primaryBackgroundColor);
        padding: 17px;
        font-size: 18px;
        font-family: "Comfortaa", cursive;
        cursor: pointer;
        line-height: 1.3em;
        transition: 0.4s;
        :hover {
          box-shadow: 0 0 14px 2px var(--shadowColor);
        }
      }
    }
  }
  @media (max-width: 630px) {
    .packages {
      .package {
        width: 90%;
      }
    }
  }
`;

export default PackagesComp;
