import React, { useEffect } from "react";
import styled from "styled-components";
import HomeBanner from "../components/homePageComponents/HomeBanner";
import Statistics from "../components/homePageComponents/Statistics";
import Quote1 from "../components/homePageComponents/Quote1";
import OurServices from "../components/homePageComponents/OurServices";
import WhyChooseUs from "../components/homePageComponents/WhyChooseUs";
import ServicePreviews from "../components/homePageComponents/ServicePreviews";
import Testimonials from "../components/homePageComponents/Testimonials";
import FAQ from "../components/homePageComponents/FAQ";
import ContactUsComp from "../components/homePageComponents/ContactUsComp";
import OurProcess from "../components/homePageComponents/OurProcess";
import ReactGA from "react-ga";
import RecentClients from "../components/homePageComponents/RecentClients";
import LimitedOffer from "../components/homePageComponents/LimitedOffer";

const Home = ({ theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "ASites Agency - Design Your Success Today";
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Container>
      <div>
        <HomeBanner theme={theme} />
        <Statistics />
        <OurServices />
      </div>
      <WhyChooseUs />
      <RecentClients />
      <OurProcess />
      <Quote1 />
      <ServicePreviews />
      <Testimonials />
      <FAQ />
      <ContactUsComp />
    </Container>
  );
};
const Container = styled.div`
  color: var(--fontPrimaryColor);
  background: var(--primaryBackgroundColor);
`;

export default Home;
