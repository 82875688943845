import React from "react";
import styled from "styled-components";

const LimitedOffer = () => {
  return <Container>LimitedOffer</Container>;
};
const Container = styled.div`
  padding: 7em 7%;
`;

export default LimitedOffer;
