import React from "react";
import styled from "styled-components";
import bannerImg from "../../assets/homePageAssets/bannerImg2.png";
import { motion } from "framer-motion";
import blackDots from "../../assets/homePageAssets/dots.png";
import whiteDots from "../../assets/homePageAssets/whiteDots.png";
import DElm1 from "../../assets/decorationElements/DElm1.png";
import { Link } from "react-router-dom";

const HomeBanner = ({ theme }) => {
  return (
    <Container>
      <div className="details">
        <h1 className="bgText">ASites</h1>
        <h3>ASites Agency</h3>
        <h1>
          Building Bridges <br />
          <motion.span
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 70 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 0.4,
              type: "spring",
            }}
          >
            to Online Success.
          </motion.span>
        </h1>
        <p>
          Unlock your brand's full potential through innovative digital
          strategies.
        </p>
        <Link to={"/get-a-proposal"}>
          <button>GET STARTED</button>
        </Link>
        <div className="tags">
          <motion.h2
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 1.4,
              type: "spring",
            }}
          >
            Growth
          </motion.h2>
          <motion.hr
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 2.7,
            }}
          />
          <motion.h2
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 1.7,
              type: "spring",
            }}
          >
            Success
          </motion.h2>
          <motion.hr
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 2.7,
            }}
          />
          <motion.h2
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 2,
              type: "spring",
            }}
          >
            Trust
          </motion.h2>
          <motion.hr
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 2.7,
            }}
          />
          <motion.h2
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.3,
              bounce: 0.3,
              delay: 2.3,
              type: "spring",
            }}
          >
            Solutions
          </motion.h2>
        </div>
        {theme === "light" ? (
          <img className="dots" src={blackDots} alt="" />
        ) : (
          <img className="dots whiteDots" src={whiteDots} alt="" />
        )}
        <img className="delm1" src={DElm1} alt="" />
      </div>
      <motion.img
        animate={{ opacity: 1, x: 0 }}
        initial={{ opacity: 0, x: 300 }}
        transition={{
          duration: 0.7,
          bounce: 0.3,
          delay: 0.4,
          type: "spring",
        }}
        src={bannerImg}
        alt="ASites Agency Banner Image"
      />
    </Container>
  );
};
const Container = styled.div`
  padding: 6em 3% 3em 3%;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  gap: 2em;
  transition: 0.3s;
  background-image: var(--backgroundGradient2);
  background-size: 30% 67%, 100% 100%, 57% 100%;
  background-repeat: no-repeat;
  img {
    width: 40%;
    max-width: 800px;
  }
  .details {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: flex-start;
    .bgText {
      position: absolute;
      font-size: 11vw;
      z-index: 0;
      font-weight: 800;
      opacity: 0.1;
    }
    h3 {
      font-weight: 300;
      color: var(--primaryColorDark);
      line-height: 0px;
      margin-left: 1em;
      z-index: 1;
    }
    h1 {
      font-size: 4.4em;
      font-weight: 400;
      transition: 0.4s;
      line-height: 1em;
      font-family: "Montserrat", cursive;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
    button {
      font-size: 18px;
      border: none;
      padding: 15px 17px;
      color: var(--primaryBackgroundColor);
      background: var(--fontPrimaryColor);
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
    .tags {
      display: flex;
      gap: 4%;
      h2 {
        font-weight: 400;
        color: var(--primaryColorDark);
      }
    }
    .dots {
      position: absolute;
      opacity: 0.1;
      top: -2em;
      width: 200px;
      display: none;
    }
    .whiteDots {
      opacity: 0.4;
    }
    .delm1 {
      position: absolute;
      width: 40px;
      left: 98%;
    }
    .statistics {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5%;
      transition: 0.3s;
      p {
        font-weight: 400;
      }
    }
  }
  @media (max-width: 1190px) {
    .details {
      h1 {
        font-size: 3.8em;
      }
    }
  }
  @media (max-width: 1040px) {
    .details {
      h1 {
        font-size: 3.3em;
      }
    }
  }
  @media (max-width: 910px) {
    .details {
      h1 {
        font-size: 3em;
      }
    }
  }
  @media (max-width: 830px) {
    flex-direction: column;
    gap: 0;
    .details {
      text-align: center;
      align-items: center;
      h1 {
        font-size: 3em;
      }
      .tags {
        justify-content: center;
      }
      .delm1 {
        left: 40%;
      }
    }
    img {
      width: 60%;
    }
  }
  @media (max-width: 580px) {
    padding: 6.4em 3% 2em 3%;
  }
  @media (max-width: 470px) {
    background-size: 70% 67%, 100% 100%, 107% 100%;
    .details {
      h1 {
        font-size: 2.2em;
      }
      .tags {
        gap: 4px;
        flex-wrap: wrap;
      }
      .delm1 {
        left: 0;
        top: 40%;
      }
    }
    img {
      width: 90%;
    }
  }
`;

export default HomeBanner;
