import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NotFoundPage = () => {
  return (
    <Container>
      <h1>Sorry, page was not found!</h1>
      <p>
        Go Back to <Link to={"/"}>Home Page</Link>
      </p>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 4%;
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  text-align: center;
  h1 {
    font-size: 4em;
    font-weight: 400;
  }
`;

export default NotFoundPage;
