import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";
import SavedPackages from "../components/packagesComponents/SavedPackages";

const AccountPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "ASites Agency - Account Page";
  }, []);

  const { currentUser, logOut } = useAuth();
  const [popUp, setPopup] = useState(false);
  return (
    <Container>
      {currentUser && currentUser.email && (
        <h2 className="acc">
          You're signed in as: <span>{currentUser.email}</span>!
        </h2>
      )}

      <button className="accBtn" onClick={() => setPopup(true)}>
        Log Out
      </button>
      {popUp === true ? (
        <div className="popup">
          <p>Are You Sure You Wanna Log Out?</p>
          <div className="btns">
            <button onClick={logOut}>Yes</button>
            <button onClick={() => setPopup(false)}>No</button>
          </div>
        </div>
      ) : (
        ""
      )}
      <SavedPackages />
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 7%;
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
  background-image: var(--backgroundGradient2T);
  background-size: 100% 100%, 110% 100%, 100% 100%;
  background-repeat: no-repeat;
  .popup {
    text-align: center;
    margin: auto;
    position: fixed;
    width: 400px;
    box-shadow: 0 0 13px 2px gray;
    left: 35%;
    padding: 30px;
    background: var(--primaryBackgroundColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btns {
      display: flex;
      gap: 7px;
      button {
        margin-top: 1em;
        font-size: 17px;
        background: #e48888;
        color: var(--primaryBackgroundColor);
        padding: 10px 17px;
        border: none;
        cursor: pointer;
        transition: 0.4s;
        :hover {
          box-shadow: 0 0 10px 3px var(--shadowColor);
        }
        :nth-child(2) {
          background-color: #92f092;
        }
      }
    }
  }
  .acc {
    text-align: center;
    margin-top: 2em;
    font-weight: 400;
    color: var(--fontSecondaryColor);
    span {
      color: var(--fontPrimaryColor);
    }
  }
  .accBtn {
    display: flex;
    margin: auto;
    margin-top: 1em;
    font-size: 17px;
    background: #e48888;
    color: var(--primaryBackgroundColor);
    padding: 14px 17px;
    border: none;
    cursor: pointer;
    transition: 0.4s;
    :hover {
      box-shadow: 0 0 10px 3px var(--shadowColor);
    }
  }
`;

export default AccountPage;
