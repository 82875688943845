import React from "react";
import styled from "styled-components";

const Statistics = () => {
  return (
    <Container>
      <p>40+ Satisfied Clients</p>
      <p>60+ Projects</p>
      <p>Professional Services</p>
    </Container>
  );
};
const Container = styled.div`
  padding: 1em 4% 1em 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  transition: 0.3s;
  background: none;
  p {
    font-weight: 400;
  }
  @media (max-width: 520px) {
    flex-wrap: wrap;
    align-items: center;
  }
`;

export default Statistics;
