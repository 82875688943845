import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const WebServicesStats = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.3 }}
      >
        <hr />
        <h2>Expertise and Experience:</h2>
        <p>
          Proven expertise; we design and optimize websites that captivate and
          perform effectively.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.6 }}
      >
        <hr />
        <h2>Tailored Solutions</h2>
        <p>
          Custom web solutions, uniquely crafted to match your specific business
          needs and aspirations.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.9 }}
      >
        <hr />
        <h2>Client-Centric Approach</h2>
        <p>
          Your vision matters. We prioritize your ideas and objectives, ensuring
          a satisfying partnership.
        </p>
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  display: flex;
  justify-content: center;
  gap: 2em;
  hr {
    border: none;
    border-bottom: 1px solid var(--primaryColor);
    margin-bottom: 10px;
  }
  h2 {
    font-weight: 500;
    font-size: 21px;
  }
  p {
    color: var(--fontSecondaryColor);
    font-weight: 300;
  }
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;

export default WebServicesStats;
