import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const WhatIsSEO = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="title">
        <h2>What is SEO?</h2>
        <p>
          SEO (Search Engine Optimization) is the strategic practice of
          enhancing a website's online visibility, <br /> driving organic
          traffic, and achieving top search rankings.
        </p>
      </div>
      <div className="bottomSection">
        <motion.div
          className="left"
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -200 }}
          transition={{
            duration: 0.7,
            bounce: 0.3,
            delay: 1,
            type: "spring",
          }}
        >
          <h2>Why SEO matters?</h2>
          <p>
            In today's digital landscape, search engines are the primary gateway
            to online information. Millions of searches are conducted daily,
            making SEO a crucial element in connecting businesses, content
            creators, and individuals with their target audiences.
          </p>
          <img
            src="https://carusodigital.com/wp-content/uploads/2021/02/8-2-seo-png-pic.png"
            alt=""
          />
        </motion.div>
        <motion.div
          className="right"
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: 200 }}
          transition={{
            duration: 0.7,
            bounce: 0.3,
            delay: 1,
            type: "spring",
          }}
        >
          <h2>The Benefits of SEO</h2>
          <div>
            <h3 ref={ref}>Increased Visibility</h3>
            <p>
              SEO efforts can help your website rank higher in search results,
              leading to more clicks and organic traffic.
            </p>
          </div>
          <div>
            <h3>Credibilty and Trust</h3>
            <p>
              Websites that appear at the top of search results are often
              perceived as more trustworthy by users.
            </p>
          </div>
          <div>
            <h3>Targeted Traffic</h3>
            <p>
              By targeting specific keywords, you attract visitors who are
              genuinely interested in your content, products, or services.
            </p>
          </div>
          <div>
            <h3>Improved User Experience</h3>
            <p>
              SEO practices often result in a better website structure and user
              experience, which can lead to increased engagement and
              conversions.
            </p>
          </div>
        </motion.div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 8%;
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
  .bottomSection {
    display: flex;
    justify-content: center;
    gap: 3em;
    margin-top: 3em;
    .left {
      width: 44%;
      h2 {
        color: var(--primaryColor);
        font-weight: 400;
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
      img {
        width: 300px;
      }
    }
    .right {
      text-align: end;
      display: flex;
      flex-direction: column;
      gap: 1em;
      h2 {
        color: var(--primaryColor);
        font-weight: 400;
        text-align: center;
      }
      div {
        h3 {
          font-weight: 400;
        }
        p {
          color: var(--fontSecondaryColor);
          font-weight: 300;
        }
      }
    }
  }
  @media (max-width: 1100px) {
    .bottomSection {
      .left {
        width: 70%;
      }
    }
  }
  @media (max-width: 860px) {
    .bottomSection {
      flex-direction: column;
      .left {
        width: 70%;
      }
    }
  }
  @media (max-width: 550px) {
    .bottomSection {
      .left {
        width: 94%;
        img {
          width: 90%;
        }
      }
    }
  }
`;

export default WhatIsSEO;
