import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const BrandingWhyChooseUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="title">
        <h2>Why Choose Us</h2>
        <p>
          Here are 3 main reasons why you should choose us for your Branding
          Services.
        </p>
      </div>
      <div className="items">
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 0.3 }}
          className="item"
        >
          <hr />
          <h2>Creative Brilliance</h2>
          <p>
            Our team blends artistic flair and market insight to create standout
            brands.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 0.6 }}
          className="item"
        >
          <hr />
          <h2>Data-Driven Strategy</h2>
          <p>
            We combine creativity with strategy, delivering brands that look
            great and drive success.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 0.6 }}
          className="item"
        >
          <hr />
          <h2>Client-Focused</h2>
          <p>
            Your goals become our mission, ensuring branding that aligns with
            your vision.
          </p>
        </motion.div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: var(--primaryColorDark);
      font-weight: 400;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
  .items {
    display: flex;
    justify-content: center;
    gap: 1em;
    margin-top: 2em;
    .item {
      width: 30%;
      h2 {
        color: var(--primaryColorDark);
        font-weight: 400;
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
    }
  }
  @media (max-width: 700px) {
    .items {
      flex-direction: column;
      align-items: center;
      .item {
        width: 90%;
      }
    }
  }
`;

export default BrandingWhyChooseUs;
