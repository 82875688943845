import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import WhatIsSEO from "../components/servicesComponents/WhatIsSEO";
import SEOTypes from "../components/servicesComponents/SEOTypes";
import SEOProcess from "../components/servicesComponents/SEOProcess";
import SEOFAQ from "../components/servicesComponents/SEOFAQ";

const SEOServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "SEO Services - ASites Agency";
  }, []);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="banner">
        <motion.img
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -100 }}
          transition={{
            duration: 0.7,
            bounce: 0.3,
            delay: 0.2,
            type: "spring",
          }}
          src="https://static.vecteezy.com/system/resources/previews/008/506/589/original/3d-seo-search-engine-optimization-concept-3d-rendering-png.png"
          alt="ASites Agency SEO Services Page Image"
        />
        <div className="left">
          <motion.h1
            animate={{ opacity: 1, x: 0 }}
            initial={{ opacity: 0, x: 70 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 0.3,
              type: "spring",
            }}
          >
            Elevate Your <span>Online Visibility</span> and{" "}
            <span>Achieve Top Rankings</span>
          </motion.h1>
          <motion.p
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            transition={{
              duration: 0.7,
              delay: 0.7,
            }}
          >
            Boost Your Website's Visibility, Drive Targeted Traffic, and Outrank
            the Competition.
          </motion.p>
          <div className="keywords">
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.5,
              }}
            >
              #seo
            </motion.h3>
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.7,
              }}
            >
              #traffic
            </motion.h3>
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 1.9,
              }}
            >
              #targetedAudience
            </motion.h3>
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.7,
                delay: 2.1,
              }}
            >
              #ranking
            </motion.h3>
          </div>
        </div>
      </div>
      <WhatIsSEO />
      <SEOTypes />
      <SEOFAQ />
      <SEOProcess />
    </Container>
  );
};
const Container = styled.div`
  color: var(--fontPrimaryColor);
  background: var(--primaryBackgroundColor);
  .banner {
    padding: 0 4% 2em 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--backgroundGradient2M);
    background-size: 30% 67%, 120% 90%, 97% 100%;
    background-repeat: no-repeat;
    .left {
      h1 {
        font-size: 3.4em;
        font-weight: 500;
        line-height: 1.2em;
        span {
          color: var(--primaryColor);
        }
      }
      p {
        font-weight: 300;
        color: var(--fontSecondaryColor);
        margin-top: 10px;
      }
      .keywords {
        display: flex;
        align-items: center;
        margin-top: 10px;
        gap: 1em;
        h3 {
          font-weight: 400;
          padding: 4px 10px;
          background: var(--secondaryBackgroundColor);
          border-radius: 20px;
        }
      }
    }
    img {
      width: 45%;
      max-width: 800px;
    }
  }
  @media (max-width: 1155px) {
    .banner {
      .left {
        h1 {
          font-size: 3em;
        }
      }
    }
  }
  @media (max-width: 930px) {
    padding: 4em 4%;
    .banner {
      img {
        width: 40%;
      }
      .left {
        h1 {
          font-size: 2.7em;
        }
        .keywords {
          flex-wrap: wrap;
        }
      }
    }
  }
  @media (max-width: 840px) {
    .banner {
      flex-direction: column-reverse;
      margin-top: 2em;
      .left {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .keywords {
          gap: 4px;
          justify-content: center;
        }
      }
      img {
        width: 70%;
      }
    }
  }
  @media (max-width: 510px) {
    .banner {
      .left {
        h1 {
          font-size: 2.3em;
        }
      }
    }
  }
  @media (max-width: 440px) {
    .banner {
      .left {
        h1 {
          font-size: 2em;
        }
      }
    }
  }
`;

export default SEOServices;
