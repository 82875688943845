import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const BrandingServicesComp = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.3 }}
        className="item"
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/logo-design-6630628-5525934.png?f=webp"
          alt=""
        />
        <h2>Logo Design</h2>
        <p>
          Craft unique, memorable logos that visually represent a brand's
          identity.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.6 }}
        className="item"
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/designer-working-on-design-5984589-4971259.png"
          alt=""
        />
        <h2>Brand Identity</h2>
        <p>
          Define key elements like brand values, mission, vision, and tone of
          voice.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 0.9 }}
        className="item"
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/graphic-design-5690847-4759147.png"
          alt=""
        />
        <h2>Visual Branding</h2>
        <p>
          Create consistent visual elements such as color schemes, typography,
          and design guidelines.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 1.2 }}
        className="item"
      >
        <img
          src="https://static.vecteezy.com/system/resources/previews/022/876/767/original/education-name-tag-3d-illustration-png.png"
          alt=""
        />
        <h2>Naming and Taglines</h2>
        <p>
          Assist clients in choosing brand names and crafting compelling
          taglines.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 1.5 }}
        className="item"
      >
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/guide-book-4349593-3611985.png?f=webp"
          alt=""
        />
        <h2>Brand Guidelines</h2>
        <p>
          Create comprehensive guidelines to maintain brand consistency across
          all touchpoints.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 1.8 }}
        className="item"
      >
        <img
          src="https://cdni.iconscout.com/illustration/premium/thumb/brand-marketing-campaign-6991882-5715511.png"
          alt=""
        />
        <h2>Brand Collateral</h2>
        <p>
          Develop creative and engaging materials like packaging, labels, and
          promotional items.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 2.1 }}
        className="item"
      >
        <img
          src="https://static.vecteezy.com/system/resources/previews/009/583/731/original/3d-illustration-content-marketing-png.png"
          alt=""
        />
        <h2>Branded Content</h2>
        <p>
          Develop content strategies and materials to engage and connect with
          the target audience.
        </p>
      </motion.div>
      <motion.div
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation1}
        transition={{ duration: 0.4, delay: 2.4 }}
        className="item"
      >
        <img
          src="https://static.vecteezy.com/system/resources/previews/010/830/016/non_2x/play-video-illustration-3d-free-png.png"
          alt=""
        />
        <h2>Brand Videos</h2>
        <p>
          Produce brand videos that convey the essence and values of a brand.
        </p>
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  .item {
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img {
      width: 150px;
    }
    h2 {
      color: var(--primaryColorDark);
      font-weight: 500;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
`;

export default BrandingServicesComp;
