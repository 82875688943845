import React, { useEffect } from "react";
import styled from "styled-components";
import dmlogo from "../assets/ASitesAgency Logo L-D-NB.png";
import lmlogo from "../assets/ASitesAgency Logo L-NB.png";
import { Link } from "react-router-dom";

const ThankYouPage = ({ theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "ASites Agency - Thank You Page";
  }, []);
  return (
    <Container>
      <div className="wrapper">
        {theme === "dark" ? (
          <img src={dmlogo} alt="" />
        ) : (
          <img src={lmlogo} alt="" />
        )}
        <h1>Thank You!</h1>
        <p>We’ve received your inquiry and we'll be in touch very soon:)</p>
        <Link to={"/packages"}>
          <button>Explore Packages</button>
        </Link>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  color: var(--fontPrimaryColor);
  background: var(--primaryBackgroundColor);
  padding: 7em 4% 3em 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-family: "Montserrat", cursive;
  background-image: var(--backgroundGradient2T);
  background-size: 100% 100%, 130% 100%, 100% 90%;
  background-repeat: no-repeat;
  height: 90vh;
  img {
    width: 100px;
  }
  h1 {
    margin-top: 10px;
    font-size: 5em;
    font-weight: 400;
  }
  p {
    margin-top: 1em;
  }
  button {
    margin-top: 1.4em;
    font-size: 18px;
    border: none;
    padding: 15px 17px;
    color: var(--primaryBackgroundColor);
    background: var(--fontPrimaryColor);
    cursor: pointer;
    transition: 0.4s;
    :hover {
      box-shadow: 0 0 14px 2px var(--shadowColor);
    }
  }
`;

export default ThankYouPage;
