import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const CCServicesComp = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation1 = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation1.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <div className="title">
        <h2>Our Content Creation Services</h2>
        <p>
          Here are our main Content Creation Services we offer, <br />
          that will help you grow very fast and very effectly.
        </p>
      </div>
      <div className="services">
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 0.3 }}
          className="service"
        >
          <img
            src="https://static.vecteezy.com/system/resources/previews/009/300/145/original/3d-illustration-of-web-development-png.png"
            alt=""
          />
          <h2>Website Content</h2>
          <p>
            Our website content makes your digital storefront inviting, informs
            visitors, and converts leads into customers, contributing to revenue
            growth.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 0.6 }}
          className="service"
        >
          <img
            src="https://static.vecteezy.com/system/resources/previews/010/986/627/original/3d-illustration-of-create-content-and-share-in-social-media-png.png"
            alt=""
          />
          <h2>Social Media Content</h2>
          <p>
            Engage your audience with fresh and shareable social media content,
            amplifying your brand's reach and fostering community growth.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 0.9 }}
          className="service"
        >
          <img
            src="https://static.vecteezy.com/system/resources/previews/011/356/147/original/teens-create-video-content-3d-character-illustration-png.png"
            alt=""
          />
          <h2>Video Content</h2>
          <p>
            Video content tells your brand's story visually, enhancing
            engagement, conveying messages effectively, and attracting new
            customers.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 1.2 }}
          className="service"
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/online-blog-4586569-3816022.png?f=webp"
            alt=""
          />
          <h2>Blog Content</h2>
          <p>
            Regular blog posts establish your authority, improve SEO, and keep
            your audience engaged, driving organic growth and building a loyal
            readership.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 1.5 }}
          className="service"
        >
          <img
            src="https://static.vecteezy.com/system/resources/previews/008/485/383/original/3d-illustration-object-icon-document-can-be-used-for-web-app-info-graphic-etc-png.png"
            alt=""
          />
          <h2>Infographics</h2>
          <p>
            Our infographics break down complex data into easily digestible
            visuals, enhancing understanding and connecting with your audience.
          </p>
        </motion.div>
        <motion.div
          ref={ref}
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate={animation1}
          transition={{ duration: 0.4, delay: 1.8 }}
          className="service"
        >
          <img
            src="https://cdn3d.iconscout.com/3d/premium/thumb/case-study-9433658-7813032.png?f=webp"
            alt=""
          />
          <h2>Case Studies</h2>
          <p>
            Developing case studies that highlight the success stories of your
            client's customers that will result into new clients.
          </p>
        </motion.div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 4%;
  .title {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: var(--primaryColor);
      font-weight: 400;
    }
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
    }
  }
  .services {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2em;
    .service {
      width: 330px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 130px;
      }
      h2 {
        font-weight: 500;
        color: var(--primaryColor);
      }
      p {
        color: var(--fontSecondaryColor);
        font-weight: 300;
      }
    }
  }
  @media (max-width: 1150px) {
    .services {
      .service {
        width: 280px;
      }
    }
  }
  @media (max-width: 985px) {
    .services {
      .service {
        width: 47%;
      }
    }
  }
  @media (max-width: 580px) {
    .services {
      .service {
        width: 97%;
      }
    }
  }
`;

export default CCServicesComp;
